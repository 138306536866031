import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const ParametricIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1885 2.16367C13.4051 3.98413 15.0468 7.7676 14.975 11.6892C14.9727 11.8173 14.9685 11.9452 14.9625 12.073C11.3717 12.3232 6.91421 11.6867 2.15212 10.2528C2.87477 6.15176 6.09654 2.91241 10.1885 2.16367ZM12.5626 2.01556C15.2642 4.37931 16.5412 8.08802 16.4748 11.7167C16.4736 11.7803 16.472 11.844 16.47 11.9077C18.3339 11.6222 19.8601 11.0601 20.9878 10.2378C21.2505 10.0462 21.493 9.8395 21.7143 9.61713C20.6884 5.42032 17.0138 2.26239 12.5626 2.01556ZM21.9804 11.369C21.9444 11.3961 21.9082 11.4231 21.8716 11.4497C20.4159 12.5114 18.5154 13.1516 16.3418 13.4412C15.8873 16.7729 14.2852 19.9855 11.4846 21.987C11.6553 21.9956 11.8271 22 12 22C17.5228 22 22 17.5229 22 12C22 11.7881 21.9934 11.5776 21.9804 11.369ZM9.18878 21.5994C12.3521 20.1112 14.2485 17.0031 14.8048 13.5861C11.1065 13.8002 6.66731 13.1513 2.00252 11.7734C2.00084 11.8488 2 11.9243 2 12C2 16.5468 5.0345 20.3849 9.18878 21.5994Z"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
