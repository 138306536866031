import type { LandingPageProps } from '@local/login';
import { LandingPage, LoginHandler } from '@local/login';
import { OrgRouteGuard } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useRoutes, Outlet } from 'react-router-dom';

import { OrgHubRewriter } from 'src/components/OrgHubRewriter';

import { ContentScaffolding } from './components/contentScaffolding/ContentScaffolding';
import { WDS2ThemeProvider } from './context/ThemeContext/ThemeContext';
import { store } from './store/store';
import { Visualization } from './visualization/Visualization/Visualization';

export function TemplateRoutes(): ReturnType<typeof useRoutes> {
    const routes = useRoutes([
        {
            path: '/',
            element: (
                <OrgRouteGuard>
                    <ContentScaffolding />
                </OrgRouteGuard>
            ),
            children: [
                {
                    // If the URL only has an org UUID, redirect to the current hub.
                    path: ':orgUuid',
                    element: <OrgHubRewriter />,
                },
                {
                    path: ':orgUuid',
                    element: <Outlet />,
                    children: [
                        {
                            path: 'hub/:hubCode',
                            element: <Outlet />,
                            children: [
                                {
                                    path: 'workspace/:workspaceUuid/project?/:projectName?/model?/:modelId?',
                                    element: <Visualization />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            index: true,
            element: <OrgHubRewriter />,
        },
    ]);

    return routes;
}

export const App = () => (
    <LoginHandler
        guardedRoutes={
            <WDS2ThemeProvider>
                <TemplateRoutes />
            </WDS2ThemeProvider>
        }
        LandingPage={({ getSignInUrl }: LandingPageProps) => (
            <LandingPage getSignInUrl={getSignInUrl} createId={false} />
        )}
        LandingPageLogo={undefined} // `undefined` results in the display of the default Seequent logo.
        store={store}
        service="evo"
        useOrgSelector
        bentleyIdEnabled
        additionalScopes={[
            'evo.discovery',
            'evo.file',
            'evo.object',
            'evo.workspace',
            'offline_access',
        ]}
    />
);
