import {
    selectCurrentModel,
    selectCurrentModelSelectedObject,
    selectIsCurrentProjectEmpty,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectShouldShowCrossSectionPanel } from 'src/store/ui/settingsPanel';
import { CrossSectionPanel } from 'src/visualization/CrossSectionPanel/CrossSectionPanel';
import { ModelSettingsPanel } from 'src/visualization/SettingsPanel/components/ModelSettingsPanel';
import { ObjectsSettingsPanel } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel';
import { ProjectSettingsPanel } from 'src/visualization/SettingsPanel/components/ProjectSettingsPanel';

const styles = { position: 'absolute', zIndex: 1, right: '16px', top: '16px' };

export function SettingsPanel() {
    const currentModel = useAppSelector(selectCurrentModel);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const isProjectEmpty = useAppSelector(selectIsCurrentProjectEmpty);
    const shouldShowCrossSectionPanel = useAppSelector(selectShouldShowCrossSectionPanel);

    if (shouldShowCrossSectionPanel) {
        return <CrossSectionPanel sx={styles} />;
    }

    if (currentModelSelectedObject && currentModelSelectedObject.visible) {
        return <ObjectsSettingsPanel sx={styles} />;
    }

    if (currentModel) {
        return <ModelSettingsPanel />;
    }

    if (!isProjectEmpty) {
        return <ProjectSettingsPanel />;
    }

    return null;
}
