import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import MenuIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { type SxProps, type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type { MouseEvent } from 'react';
import { useState } from 'react';

import { BoundaryIcon } from 'src/assets/BoundaryIcon';
import { BoxSearchIcon } from 'src/assets/BoxSearchIcon';
import { MeshIcon } from 'src/assets/MeshIcon';
import { WorkspacesIcon } from 'src/assets/WorkspacesIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import type { GtmEvoOutputObject, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager, useSceneObjectSelectionManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deselectSelectedObject,
    removeInputObjectsFromProject,
    setSelectedObjectIndex,
    updateInputObject,
} from 'src/store/project/projectSlice';
import { selectProjectInputObjects, selectSelectedObjectIndex } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    BoundaryCreationState,
    selectIsCreatingBoundary,
    setBoundaryCreationState,
    setOpenUploadObjectsDialog,
    switchToWorkspaceMode,
} from 'src/store/ui/projectPanel';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import {
    HideShowButtons,
    ObjectType,
} from 'src/visualization/ProjectPanel/components/HideShowButtons';
import { PanelItemMenu } from 'src/visualization/ProjectPanel/components/PanelItemMenu';
import {
    ADD_FROM_WORKSPACE,
    DEFINE_ANALYTICAL_BOUNDARY_LABEL,
    EMPTY_OBJECTS_MESSAGE,
    getRemoveObjectFromProjectDescription,
    NO_OBJECTS_YET_MESSAGE,
    OBJECTS_LABEL,
    UPLOAD_LABEL,
    UPLOAD_OBJECT,
    WORKSPACE_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export const InputObjects = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const inputObjects = useAppSelector(selectProjectInputObjects);
    const showSetBoundaryDialog = useAppSelector(selectIsCreatingBoundary);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    if (inputObjects.length === 0) {
        return <InputObjectsEmptyState />;
    }

    const handleOpenUploadObjectsDialog = () => {
        dispatch(setOpenUploadObjectsDialog(true));
    };

    const handleSwitchToWorkspaceMode = () => {
        dispatch(switchToWorkspaceMode());
    };

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'space-between',
                    padding: theme.spacing(1.5, 2, 1, 2),
                    alignItems: 'center',
                    mt: 1,
                }}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {OBJECTS_LABEL}
                </Typography>
                <IconButton
                    sx={{ padding: 0 }}
                    size="small"
                    onClick={(e) => {
                        setMenuAnchorEl(e.currentTarget);
                    }}
                >
                    <MenuIcon fontSize="small" />
                </IconButton>
            </Stack>
            <Box p={2}>
                <Box
                    sx={{
                        maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                        overflowY: 'auto',
                        borderRadius: theme.spacing(0.5),
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    <List dense disablePadding>
                        {inputObjects.map((object, index) => (
                            <InputObjectsListItem
                                key={object.id}
                                index={index}
                                object={object}
                                isLastItem={index === inputObjects.length - 1}
                            />
                        ))}
                    </List>
                </Box>
            </Box>
            {!showSetBoundaryDialog && (
                <Box p={2}>
                    <Button
                        startIcon={<BoundaryIcon />}
                        sx={{ width: '100%' }}
                        size="small"
                        variant="contained"
                        onClick={() =>
                            dispatch(setBoundaryCreationState(BoundaryCreationState.DrawRectangle))
                        }
                    >
                        {DEFINE_ANALYTICAL_BOUNDARY_LABEL}
                    </Button>
                </Box>
            )}
            <PanelItemMenu
                anchorEl={menuAnchorEl}
                onClose={() => {
                    setMenuAnchorEl(null);
                }}
                customEntries={[
                    {
                        label: ADD_FROM_WORKSPACE,
                        onClick: handleSwitchToWorkspaceMode,
                        Icon: WorkspacesIcon,
                    },
                    {
                        label: UPLOAD_OBJECT,
                        onClick: handleOpenUploadObjectsDialog,
                        Icon: UploadIcon,
                    },
                ]}
            />
        </>
    );
};

const InputObjectsEmptyState = () => {
    const applyTrace = useTrace('input-objects-empty-state');
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const handleWorkspaceOnClick = () => {
        dispatch(switchToWorkspaceMode());
    };

    const handleUploadObjectsOnClick = () => {
        dispatch(setOpenUploadObjectsDialog(true));
    };

    return (
        <Stack
            automation-id={applyTrace('root')}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                padding: theme.spacing(3),
            }}
        >
            <BoxSearchIcon fontSize="large" />
            <Typography
                sx={{ margin: theme.spacing(0.5, 0, 1, 0) }}
                color="textSecondary"
                variant="h5"
            >
                {NO_OBJECTS_YET_MESSAGE}
            </Typography>
            <Typography color="textSecondary" variant="subtitle2">
                {EMPTY_OBJECTS_MESSAGE}
            </Typography>
            <Stack
                spacing={2}
                sx={{ marginTop: theme.spacing(2), textTransform: 'capitalize' }}
                direction="row"
            >
                <Button
                    automation-id={applyTrace('workspace-button')}
                    variant="outlined"
                    size="small"
                    startIcon={<WorkspacesIcon />}
                    onClick={handleWorkspaceOnClick}
                >
                    {WORKSPACE_LABEL}
                </Button>
                <Button
                    automation-id={applyTrace('upload-button')}
                    variant="contained"
                    size="small"
                    startIcon={<UploadIcon />}
                    onClick={handleUploadObjectsOnClick}
                >
                    {UPLOAD_LABEL}
                </Button>
            </Stack>
        </Stack>
    );
};

export const InputObjectsListItem = ({
    object,
    index,
    isLastItem,
}: Readonly<{
    object: GtmProjectInput;
    index: number;
    isLastItem?: boolean;
}>) => {
    const dispatch = useAppDispatch();
    const {
        removeGtmObject,
        isObjectOnPlotByObjectId,
        highlightSceneObject,
        removeHighlightFromHighlightedObject,
    } = useSceneObjectDataManager();
    const selectObjectIndex = useAppSelector(selectSelectedObjectIndex);
    const { getZoomToViewTool } = useBaseXyz();
    const { syncProject } = useProjectSynchronizer();
    const { onObjectControlSelection, onObjectDeselect } = useSceneObjectSelectionManager();
    const sceneObject = useAppSelector(sceneObjectById(object.id));

    const handleRemoveItemOnClick = async (event: MouseEvent) => {
        event.stopPropagation();
        dispatch(removeInputObjectsFromProject(object));
        dispatch(updateInputObject([object.id, { ...object, visible: false }]));
        syncProject({
            description: getRemoveObjectFromProjectDescription(object),
        });
        removeGtmObject(object.id);
    };

    const handleOnClick = (event: MouseEvent) => {
        if (selectObjectIndex === index) {
            dispatch(deselectSelectedObject());
            onObjectDeselect(object.id);
            removeHighlightFromHighlightedObject();
        } else {
            // TODO: Handle multiselect
            onObjectControlSelection([], index, object.id, event);
            dispatch(setSelectedObjectIndex(index));
            if (isObjectOnPlotByObjectId(object.id)) {
                if (sceneObject) {
                    highlightSceneObject(sceneObject);
                }
                getZoomToViewTool().zoomToView(object.id);
            }
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                selected={selectObjectIndex === index}
                sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={object} objectType={ObjectType.InputObject} />
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: '16px', margin: 'auto 0' }}
                />
                <MeshIcon fontSize="small" sx={{ marginLeft: 1 }} />
                <InputObjectName sx={{ paddingLeft: 1 }} object={object} />
                <IconButton edge="end" onClick={handleRemoveItemOnClick} size="small">
                    <RemoveIcon />
                </IconButton>
            </ListItemButton>
        </ListItem>
    );
};

function InputObjectName({
    object,
    sx = [],
}: Readonly<{ object: GtmEvoOutputObject | GtmProjectInput; sx?: SxProps<Theme> }>) {
    return (
        <ListItemText
            disableTypography
            primary={
                <Stack direction="row" spacing={1.25} paddingRight="5px">
                    <OverflowTooltipTypography
                        variant="body2"
                        sx={[
                            {
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                            ...(Array.isArray(sx) ? sx : [sx]),
                        ]}
                    >
                        {fileNameExtensionRemover(object.name)}
                    </OverflowTooltipTypography>
                </Stack>
            }
        />
    );
}
