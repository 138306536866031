import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'src/store/store';
import type { ProjectPanelState } from 'src/store/ui/projectPanel/projectPanel.types';
import {
    ModelViewTabSelection,
    BoundaryCreationState,
    isCreatingBoundary,
    ProjectPanelMode,
} from 'src/store/ui/projectPanel/projectPanel.types';

export const initialProjectPanelState: ProjectPanelState = {
    projectPanelMode: ProjectPanelMode.Project,
    isSearchingWorkspace: false,
    workspaceSearchTerm: '',
    selectedWorkspaceObjectIds: [],
    shouldMinimizeProjectPanel: false,
    shouldOpenNoProjectDialog: false,
    shouldShowProjectPanel: true,
    openUploadObjectsDialog: false,
    boundaryCreationState: BoundaryCreationState.None,
    modelViewTabSelection: ModelViewTabSelection.Triangulated,
    shouldOpenDetectionSettingsDialog: false,
};

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorProjectPanelMode = (state: RootState) => ProjectPanelMode;
type SelectorTypeStringArray = (state: RootState) => string[];
type SelectorBoundaryCreationState = (state: RootState) => BoundaryCreationState;
type SelectorModelViewTabSelection = (state: RootState) => ModelViewTabSelection;

const projectPanelState = (state: RootState): ProjectPanelState => state.projectPanel;
const selectProjectPanelMode: SelectorProjectPanelMode = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.projectPanelMode,
);
export const selectIsProjectMode: SelectorTypeBoolean = createSelector(
    selectProjectPanelMode,
    (projectPanelMode) => projectPanelMode === ProjectPanelMode.Project,
);
export const selectIsModelMode: SelectorTypeBoolean = createSelector(
    selectProjectPanelMode,
    (projectPanelMode) => projectPanelMode === ProjectPanelMode.Model,
);
export const selectIsWorkspaceMode: SelectorTypeBoolean = createSelector(
    selectProjectPanelMode,
    (projectPanelMode) => projectPanelMode === ProjectPanelMode.Workspace,
);
export const selectIsSearchingWorkspaceObjects: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.isSearchingWorkspace,
);
export const selectWorkspaceSearchTerm: SelectorTypeString = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.workspaceSearchTerm,
);
export const selectSelectedWorkspaceObjectIds: SelectorTypeStringArray = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.selectedWorkspaceObjectIds,
);
export const selectShouldMinimizeProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldMinimizeProjectPanel,
);
export const selectShouldOpenNoProjectDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldOpenNoProjectDialog,
);
export const selectShouldShowProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldShowProjectPanel,
);
export const selectIsCreatingBoundary: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => isCreatingBoundary(projectPanelStateRoot.boundaryCreationState),
);
export const selectOpenUploadObjectsDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.openUploadObjectsDialog,
);
export const selectBoundaryCreationState: SelectorBoundaryCreationState = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.boundaryCreationState,
);
export const selectModelViewTabSelection: SelectorModelViewTabSelection = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.modelViewTabSelection,
);
export const selectShouldOpenDetectionSettingsDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldOpenDetectionSettingsDialog,
);
