import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const MeshIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.34311 12.7565L7.95514 18.4756L11.2662 12.1545L4.34311 12.7565ZM9.90032 19.0723L17.008 17.8877L13.158 12.8531L9.90032 19.0723ZM18.2309 16.1945L19.5715 10.564L14.7454 11.6365L18.2309 16.1945ZM17.9172 8.88283L11.9711 5.31515L13.636 9.83421L17.9172 8.88283ZM9.601 4.66728L5.01163 10.6908L11.6088 10.1172L9.601 4.66728ZM8.14373 3.27986C8.91575 2.26658 10.3262 1.99583 11.4185 2.65123L20.43 8.05811C21.3647 8.6189 21.8282 9.72057 21.5758 10.7809L19.8841 17.8858C19.6512 18.864 18.855 19.6075 17.8631 19.7728L9.1378 21.227C8.14531 21.3924 7.15037 20.9467 6.61308 20.096L2.4273 13.4685C1.86966 12.5856 1.91956 11.4491 2.55244 10.6184L8.14373 3.27986Z"
                fill="#379BE0"
            />
        </g>
    </SvgIcon>
);
