import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { matchPath } from 'react-router-dom';

export const getOrgUuidAndWorkSpaceUuid = () => {
    const match = matchPath(
        '/:orgUuid/hub/:hubCode/workspace/:workspaceUuid/*',
        window.location.pathname,
    );
    const orgUuid = getOrgUuidFromParams(match?.params ?? {});
    const workspaceUuid = getSelectedWorkspaceFromParams(match?.params ?? {});
    return { orgUuid, workspaceUuid };
};
