import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import BuildOutlined from '@mui/icons-material/BuildOutlined';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    DEFAULT_INITIALCLEANUP,
    DEFAULT_ISCLOSED,
    DEFAULT_LINEPROXIMITYDETECTION,
    DEFAULT_OPTIMIZE,
    DEFAULT_SHAPEQUALITYWEIGHT,
    DEFAULT_STRAINTOLERANCE,
    DEFAULT_TARGETH,
} from 'src/apiClients/gtmCompute/gtmComputeApi.constants';
import type { GtmLocalRemeshParams } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModelSelectedObject,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    localRemeshSettingsFromAnalyticalModelSettings,
    setAllLocalRemeshSettings,
} from 'src/store/ui/localRemeshSettings';
import { assert } from 'src/utils/gtmAssert';
import {
    FIX_DEGENERATE_TRIANGLES_LABEL,
    FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import { LocalRemeshSettingsDialog } from './LocalRemeshSettingsDialog';

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Uploading, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Complete, FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 60],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

export const FixDegenerateTriangles = () => {
    const theme = useTheme();
    const applyTrace = useTrace('fix-degenerate-triangles');
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus, resetTransformationStatus } =
        useTransformationManager();
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { isAggregate } = useObjectManager();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(
        selectedObject !== undefined,
        'Degenerate triangle fixer does not know to which object it applies.',
    );
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const [showingRemeshSettings, setShowingRemeshSettings] = useState(false);
    const [anchorElRemeshSettings, setAnchorElRemeshSettings] = useState<null | HTMLElement>(null);
    const currentAnalyticalModelSettings =
        useAppSelector(selectCurrentAnalyticalModelSettings) ?? defaultAnalyticalModelSettings;
    const degenTriDetection = useAppSelector(
        issueDataForObjectAndAction(selectedObject!.id, GtmMeshDetectorAction.DetectDegenerateTris),
    );
    const triIndices = degenTriDetection![0].triangles;

    const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
        const remeshSettings = localRemeshSettingsFromAnalyticalModelSettings(
            currentAnalyticalModelSettings,
        );
        dispatch(setAllLocalRemeshSettings(remeshSettings));
        setAnchorElRemeshSettings(event.currentTarget);
        setShowingRemeshSettings(!showingRemeshSettings);
    };

    const handleCloseSettings = () => {
        setShowingRemeshSettings(!showingRemeshSettings);
        setAnchorElRemeshSettings(null);
    };

    const handleFix = () => {
        if (!selectedObject) {
            return;
        }

        // TODO: have a shared place to store the rest of the default params between here and the dialog
        const { localRemeshSettings } = currentAnalyticalModelSettings;
        const params: GtmLocalRemeshParams = {
            patchAngleTolerance: localRemeshSettings.patchAngleTolerance,
            maxChordalError: localRemeshSettings.maxChordalError,
            radius: localRemeshSettings.radius,
            seedTriInds: triIndices,
            regularizeMode: true,
            shapeQualityWeight: DEFAULT_SHAPEQUALITYWEIGHT,
            targetH: DEFAULT_TARGETH,
            isClosed: DEFAULT_ISCLOSED, // TODO: set from object once we have that information
            lineProximityDetection: DEFAULT_LINEPROXIMITYDETECTION,
            strainTolerance: DEFAULT_STRAINTOLERANCE,
            initialCleanup: DEFAULT_INITIALCLEANUP,
            optimize: DEFAULT_OPTIMIZE,
        };

        executeTransformation(
            GtmMeshTransformationAction.LocalRemesh,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    if (isAggregate(selectedObject.id)) {
                        resetVolumes();
                        resetParameterizedVolumes();
                    }
                },
            },
        );
    };

    return (
        <>
            <Stack direction="row">
                <Button
                    automation-id={applyTrace('fix-button')}
                    size="small"
                    sx={{ flexGrow: 1 }}
                    variant="contained"
                    onClick={handleFix}
                    startIcon={<BuildOutlined />}
                >
                    {FIX_DEGENERATE_TRIANGLES_LABEL}
                </Button>
                <IconButton
                    automation-id={applyTrace('settings')}
                    size="small"
                    onClick={handleOpenSettings}
                    sx={{ ml: theme.spacing(1) }}
                >
                    <TuneOutlined fontSize="small" sx={{ fill: theme.palette.primary.main }} />
                </IconButton>
            </Stack>
            {anchorElRemeshSettings && (
                <LocalRemeshSettingsDialog
                    modelIndex={selectedModelIndex}
                    anchorEl={anchorElRemeshSettings}
                    triIndices={triIndices}
                    executeTransformation={executeTransformation}
                    onClose={handleCloseSettings}
                />
            )}
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
                resetStatus={resetTransformationStatus}
            />
        </>
    );
};
