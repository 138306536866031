import Ajv from 'ajv';

import type { SchemaVersion } from 'src/gtmProject/Project.types';

export function isSameVersion(v1: SchemaVersion, v2: SchemaVersion): boolean {
    return v1.model === v2.model && v1.revision === v2.revision && v1.addition === v2.addition;
}

/**
 * Validates a project JSON file against a schema version.
 * Schemas are fetched from static files in the public folder.
 */
export async function isValid(projectJson: any, version: SchemaVersion): Promise<boolean> {
    const ajv = new Ajv();
    const response = await fetch(
        `/schemas/gtm-project-schema-${version.model}-${version.revision}-${version.addition}.json`,
    );
    const schemaJson = await response.json();
    const validate = ajv.compile(schemaJson);
    return validate(projectJson);
}
