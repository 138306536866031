import { useTrace } from '@local/web-design-system-2';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import type { ReactElement } from 'react';

import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { PercentProgress } from 'src/components/PercentProgress/PercentProgress';
import { TransformationStatus } from 'src/hooks/transformation/useTransformationManager';
import { CLOSE_LABEL } from 'src/strings';

const iconsByStatus = new Map<TransformationStatus, JSX.Element>([
    [
        TransformationStatus.Transforming,
        <BuildCircleOutlinedIcon color="primary" fontSize="large" />,
    ],
    [TransformationStatus.Uploading, <BuildCircleOutlinedIcon color="primary" fontSize="large" />],
    [TransformationStatus.Complete, <CheckCircle color="success" fontSize="large" />],
    [TransformationStatus.Failed, <WarningIcon color="error" fontSize="large" />],
]);

interface TransformationProgressModalBaseProps {
    transformationStatus: TransformationStatus | undefined;
    transformationTitles: Map<TransformationStatus, string>;
    transformationMessages?: Map<TransformationStatus, string>;
    transformationPercentages?: Map<TransformationStatus, number>;
}

interface TransformationModalWithActions extends TransformationProgressModalBaseProps {
    actions: ReactElement;
    resetStatus?: never;
}

interface TransformationModalWithResetStatus extends TransformationProgressModalBaseProps {
    actions?: never;
    resetStatus: () => void;
}

type TransformationModalProps = TransformationModalWithResetStatus | TransformationModalWithActions;

const finalStatuses = [
    TransformationStatus.Failed,
    TransformationStatus.Complete,
    TransformationStatus.Cancelled,
];

export function TransformationProgressModal({
    transformationStatus,
    transformationTitles,
    transformationMessages,
    transformationPercentages,
    resetStatus,
    actions,
}: Readonly<TransformationModalProps>) {
    const applyTrace = useTrace('transformation-progress-model');
    const percentage = transformationPercentages?.get(transformationStatus!);

    const hasPercentages = transformationPercentages !== undefined && percentage !== undefined;
    const progressBar =
        hasPercentages && transformationStatus !== TransformationStatus.Failed ? (
            <PercentProgress percent={percentage} showPercentText />
        ) : undefined;

    const title =
        transformationStatus !== undefined
            ? (transformationTitles.get(transformationStatus) ?? '')
            : '';
    const message =
        transformationStatus !== undefined
            ? (transformationMessages?.get(transformationStatus) ?? '')
            : undefined;
    const isFinalStatus =
        transformationStatus !== undefined && finalStatuses.includes(transformationStatus);

    return (
        <ActionDialog
            open={transformationStatus !== undefined}
            title={title}
            message={message}
            additionalContent={progressBar}
            icon={
                transformationStatus !== undefined
                    ? iconsByStatus.get(transformationStatus)
                    : undefined
            }
            actions={
                isFinalStatus &&
                (actions ?? (
                    <Button
                        automation-id={applyTrace('close-button')}
                        size="small"
                        sx={{ ml: 'auto' }}
                        onClick={resetStatus}
                    >
                        {CLOSE_LABEL}
                    </Button>
                ))
            }
        />
    );
}
