import { useMemo } from 'react';

import {
    defaultAnalyticalModelSettings,
    formGtmMeshDetectorBody,
    useLazyGtmMeshDetectorQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import type {
    GtmMeshDetectDegenerateTriangleParams,
    GtmMeshDetectHolesParams,
    GtmMeshDetectorParams,
    GtmMeshDetectSelfIntersectionsParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { GtmAnalyticalModel, GtmAnalyticalModelSettings } from 'src/gtmProject';
import { useIssueManager } from 'src/hooks/issues/useIssueManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { GtmObjectType } from 'src/store/project/projectSlice.types';
import { makeSelectIsObjectOutdatedOrMissing } from 'src/store/project/selectors';
import { useGetLatestState } from 'src/store/store';
import type { ObjectId, ObjectIdWithVersion } from 'src/types/core.types';

// We implemented these detectors but they currently serve no purpose so are disabled
const disabledDetectors: GtmMeshDetectorAction[] = [
    // We work around non-manifold geometry
    GtmMeshDetectorAction.DetectFins,
    GtmMeshDetectorAction.DetectNonManifoldEdges,
    GtmMeshDetectorAction.DetectNonManifoldVertices,
    // Most of our algorithms don't care about individual triangle orientation
    GtmMeshDetectorAction.DetectInconsistentlyOrientedTris,
    // We merge close (duplicate) vertices when we create the analytical boundary
    // We should never create new close (duplicate) vertices.
    // At the time of disabling this, we also don't have a transformation exposed to fix
    // duplicate points.
    GtmMeshDetectorAction.DetectDuplicatePoints,
];

export function detectorIsApplicable(
    action: GtmMeshDetectorAction,
    versionedObject: ObjectIdWithVersion,
    objectType: (id: ObjectId) => GtmObjectType | undefined,
) {
    if (disabledDetectors.includes(action)) return false;

    switch (objectType(versionedObject.id)) {
        case GtmObjectType.AggregateGeometry:
            return true;

        default:
            return action !== GtmMeshDetectorAction.DetectNonPartitioningSurfaces;
    }
}

export function detectorTargetObjectsInAnalyticalModel(model: GtmAnalyticalModel) {
    return [...model.objects, model.aggregateGeometry];
}

export function getDetectorParams(
    analyticalModelSettings: GtmAnalyticalModelSettings,
    action: GtmMeshDetectorAction,
): GtmMeshDetectorParams {
    switch (action) {
        case GtmMeshDetectorAction.DetectDegenerateTris:
            return analyticalModelSettings.degenerateTriangleSettings as GtmMeshDetectDegenerateTriangleParams;
        case GtmMeshDetectorAction.DetectHoles:
            return analyticalModelSettings.holeSettings as GtmMeshDetectHolesParams;
        case GtmMeshDetectorAction.DetectSelfIntersections:
            return analyticalModelSettings.selfIntersectionSettings as GtmMeshDetectSelfIntersectionsParams;

        default:
            return {};
    }
}

export function useDefectsLoadingManager() {
    const { runDetector } = useSingleDetectorRunner();

    async function runAllDetectors(
        versionedObject: ObjectIdWithVersion,
        analytialModelSettings: GtmAnalyticalModelSettings,
    ) {
        await Promise.all(
            Object.values(GtmMeshDetectorAction).map((detectorAction) =>
                runDetector(versionedObject, detectorAction, analytialModelSettings),
            ),
        );
    }

    async function runSelectedDetectors(
        versionedObject: ObjectIdWithVersion,
        selectedDetectors: GtmMeshDetectorAction[],
        analytialModelSettings: GtmAnalyticalModelSettings,
    ) {
        await Promise.all(
            Object.values(selectedDetectors).map((detectorAction) =>
                runDetector(versionedObject, detectorAction, analytialModelSettings),
            ),
        );
    }

    return { runAllDetectors, runSelectedDetectors };
}

export function useSingleDetectorRunner() {
    const { objectType } = useObjectManager();
    const { setError, setIssueData } = useIssueManager();
    const { makeDetectionQuery } = useDetectionQuery();
    const memoizedSelectIsObjectOutdatedOrMissing = useMemo(
        makeSelectIsObjectOutdatedOrMissing,
        [],
    );
    const getLatestState = useGetLatestState();

    return {
        runDetector: async (
            versionedObject: ObjectIdWithVersion,
            detectorAction: GtmMeshDetectorAction,
            analyticalModelSettings: GtmAnalyticalModelSettings,
        ) => {
            if (detectorIsApplicable(detectorAction, versionedObject, objectType)) {
                const params = getDetectorParams(
                    analyticalModelSettings ?? defaultAnalyticalModelSettings,
                    detectorAction,
                );
                setError(versionedObject.id, detectorAction, false);

                try {
                    const data = await makeDetectionQuery(detectorAction, versionedObject, params);
                    const isObjectOutdatedOrMissing = getLatestState((state) =>
                        memoizedSelectIsObjectOutdatedOrMissing(state, versionedObject),
                    );
                    if (isObjectOutdatedOrMissing) return;
                    setIssueData(versionedObject.id, detectorAction, data);
                } catch (error) {
                    setError(versionedObject.id, detectorAction, true);
                }
            }
        },
    };
}

function useDetectionQuery() {
    const gooseContext = useGooseContext();
    const [GtmMeshDetectionTrigger] = useLazyGtmMeshDetectorQuery();
    const { setLoading } = useIssueManager();

    return {
        makeDetectionQuery: async (
            detectionAction: GtmMeshDetectorAction,
            versionedObject: ObjectIdWithVersion,
            params: GtmMeshDetectorParams,
        ) => {
            setLoading(versionedObject.id, detectionAction, true);
            const { data, isError } = await GtmMeshDetectionTrigger(
                formGtmMeshDetectorBody(gooseContext!, detectionAction, [versionedObject], params),
            );
            setLoading(versionedObject.id, detectionAction, false);

            return isError || !data ? Promise.reject() : data;
        },
    };
}
