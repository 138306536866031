import Stack from '@mui/material/Stack';
import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import round from 'lodash-es/round';
import { useEffect, useState } from 'react';

import {
    COORDINATE_VALUE_PRECISION,
    X_LABEL,
    Y_LABEL,
    Z_LABEL,
} from 'src/visualization/Common/CoordinatesInput.constants';
import type {
    Axis,
    CoordinatesDisabledState,
    CoordinatesErrorState,
    Point,
} from 'src/visualization/Common/CoordinatesInput.types';

interface CoordinatesInputProps {
    label?: Readonly<string>;
    coordinateValues: Readonly<Point>;
    onChange: (axis: Axis, updatedValue: number) => void;
    errorState: Readonly<CoordinatesErrorState>;
    disabledState: Readonly<CoordinatesDisabledState>;
}

function OutlinedTextFieldSizedTextProps(
    themeSpacing: number,
): Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> {
    return {
        InputProps: {
            sx: {
                fontSize: (theme) => theme.spacing(themeSpacing),
            },
        },
        InputLabelProps: {
            sx: { fontSize: (theme) => theme.spacing(themeSpacing) },
        },
    };
}

export function CoordinatesInput({
    label,
    coordinateValues,
    onChange,
    errorState,
    disabledState,
}: Readonly<CoordinatesInputProps>) {
    const { x, y, z } = coordinateValues;

    const [xText, setXText] = useState(round(x, COORDINATE_VALUE_PRECISION).toString());
    const [yText, setYText] = useState(round(y, COORDINATE_VALUE_PRECISION).toString());
    const [zText, setZText] = useState(round(z, COORDINATE_VALUE_PRECISION).toString());

    function setText(axis: Axis, value: string) {
        switch (axis) {
            case 'x':
                setXText(value);
                break;
            case 'y':
                setYText(value);
                break;
            case 'z':
                setZText(value);
                break;
            default:
                break;
        }
    }

    function handleChange(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        axis: Axis,
    ) {
        setText(axis, event.target.value);
    }

    function changeCoordinateOnEvent(axis: Axis, value: string) {
        const parsedValue = parseFloat(value);
        onChange(axis, Number.isFinite(parsedValue) ? parsedValue : NaN);
        setText(axis, value);
    }

    function handleBlur(
        event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
        axis: Axis,
    ) {
        changeCoordinateOnEvent(axis, event.target.value);
    }

    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>, axis: Axis) {
        if (event.key === 'Enter') {
            changeCoordinateOnEvent(axis, event.currentTarget.value);
        }
    }

    const createTextFieldProps = (axis: Axis) => () => {
        const textFieldProps = OutlinedTextFieldSizedTextProps(1.75);
        textFieldProps.InputProps = {
            ...textFieldProps.InputProps,
            onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => {
                handleKeyUp(event, axis);
            },
        };
        return textFieldProps;
    };

    useEffect(() => {
        setXText(Number.isFinite(x) ? round(x, COORDINATE_VALUE_PRECISION).toString() : xText);
        setYText(Number.isFinite(y) ? round(y, COORDINATE_VALUE_PRECISION).toString() : yText);
        setZText(Number.isFinite(z) ? round(z, COORDINATE_VALUE_PRECISION).toString() : zText);
    }, [x, y, z]);

    return (
        <>
            {label !== undefined && (
                <Typography
                    fontSize="small"
                    color="textSecondary"
                    sx={{
                        marginTop: (theme) => theme.spacing(1),
                        marginBottom: (theme) => theme.spacing(1),
                    }}
                >
                    {label}
                </Typography>
            )}
            <Stack direction="row" spacing={1}>
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={disabledState.isXDisabled}
                    size="small"
                    variant="outlined"
                    error={errorState.isErrorX}
                    value={xText}
                    label={X_LABEL}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'x');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'x');
                    }}
                    {...createTextFieldProps('x')()}
                />
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={disabledState.isYDisabled}
                    size="small"
                    variant="outlined"
                    error={errorState.isErrorY}
                    label={Y_LABEL}
                    value={yText}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'y');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'y');
                    }}
                    {...createTextFieldProps('y')()}
                />
                <TextField
                    type="text"
                    inputMode="numeric"
                    disabled={disabledState.isZDisabled}
                    size="small"
                    variant="outlined"
                    error={errorState.isErrorZ}
                    label={Z_LABEL}
                    value={zText}
                    onChange={(
                        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                    ) => {
                        handleChange(event, 'z');
                    }}
                    onBlur={(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        handleBlur(event, 'z');
                    }}
                    {...createTextFieldProps('z')()}
                />
            </Stack>
        </>
    );
}
