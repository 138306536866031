import { WDSThemeProvider } from '@local/web-design-system-2';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';

import { CANCEL } from './DrawingModeToolbar.constants';

interface DrawingModeToolbarProps {
    onCancel: () => void;
    icon: React.ReactNode;
    prompt: string;
    'automation-id'?: string;
}

export const DrawingModeToolbar = ({
    onCancel,
    icon,
    prompt,
    'automation-id': automationId,
}: Readonly<DrawingModeToolbarProps>) => {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const styledTheme = useTheme();
    const iconPaddingTopBottom = styledTheme.spacing(0.75);

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '33px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
            }}
            automation-id={automationId}
        >
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <Stack
                        sx={{ alignItems: 'center', paddingLeft: 1, paddingRight: 1 }}
                        direction="row"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: `${iconPaddingTopBottom}`,
                                paddingBottom: `${iconPaddingTopBottom}`,
                            }}
                        >
                            {icon}
                        </Box>
                        <Typography
                            color="textPrimary"
                            variant="body2"
                            sx={{
                                marginLeft: (theme) => theme.spacing(0.5),
                                marginRight: (theme) => theme.spacing(0.5),
                                width: 'max-content',
                            }}
                        >
                            {prompt}
                        </Typography>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                marginLeft: (theme) => theme.spacing(0.5),
                                marginRight: (theme) => theme.spacing(0.5),
                            }}
                        />
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<CloseIcon />}
                            onClick={onCancel}
                        >
                            {CANCEL}
                        </Button>
                    </Stack>
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
};
