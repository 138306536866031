import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import type { CameraState } from '@local/webviz/dist/types/xyz';
import { useParams } from 'react-router-dom';

import type {
    AggregatableObject,
    GtmAnalyticalModel,
    GtmEvoOutputObject,
    GtmHistoryEntry,
    GtmProject,
    GtmProjectInput,
} from 'src/gtmProject';
import { isGtmAnalyticalModel } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useLazyFetchGtmProjectFile } from 'src/hooks/evoContext/useLazyFetchGtmProjectFile';
import { skipHistoryEntry, useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deselectSelectedObject,
    overwriteProject,
    setCurrentModelAggregateObjectAsSelected,
    setSelectedModelIndex,
    setSelectedObjectIndex,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModelSelectedObject,
    selectCurrentProjectData,
    selectCurrentProjectRedoEntries,
    selectCurrentProjectUndoEntries,
    selectCurrentProjectVersionId,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector, useGetLatestState } from 'src/store/store';
import { ModelViewTabSelection, selectModelViewTabSelection } from 'src/store/ui/projectPanel';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { addOrUpdateSceneObject } from 'src/store/visualization/visualizationSlice';
import type { ObjectId } from 'src/types/core.types';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';

export function useHistoryManager() {
    const [FetchGtmProjectFileTrigger] = useLazyFetchGtmProjectFile();
    const dispatch = useAppDispatch();
    const currentProject = useAppSelector(selectCurrentProjectData);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const projectUndoEntries = useAppSelector(selectCurrentProjectUndoEntries);
    const projectRedoEntries = useAppSelector(selectCurrentProjectRedoEntries);
    const currentProjectVersionId = useAppSelector(selectCurrentProjectVersionId);
    const { syncProject } = useProjectSynchronizer();
    const {
        refreshVisualizationForProject,
        runDetectorsForModel,
        clearVisualizationAndIssues,
        renderParametricObjects,
        renderTriangulatedObjects,
    } = useConglomerateActionManager();
    const { highlightSceneObject } = useSceneObjectDataManager();
    const { getEntityState, addViewStatusListener, setStateFromSnapshot } = useBaseXyz();
    const { modelId } = useParams();
    const { navigateToModelUrl } = useGtmNavigator();
    const modelViewTabSelection = useAppSelector(selectModelViewTabSelection);
    const getLatestState = useGetLatestState();

    async function undoOperation() {
        const lastUndoEntry = projectUndoEntries.at(-1);
        if (!lastUndoEntry) {
            return;
        }
        const [project] =
            (await FetchGtmProjectFileTrigger(currentProject.name, lastUndoEntry.versionId)) ?? [];
        if (!project) {
            return;
        }
        const updatedUndoEntries = projectUndoEntries.slice(0, -1);
        const updatedRedoEntries = [
            ...projectRedoEntries,
            { ...lastUndoEntry, versionId: currentProjectVersionId },
        ];
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    async function redoOperation() {
        const lastRedoEntry = projectRedoEntries.at(-1);
        if (!lastRedoEntry) {
            return;
        }
        const [project] =
            (await FetchGtmProjectFileTrigger(currentProject.name, lastRedoEntry.versionId)) ?? [];
        if (!project) {
            return;
        }

        const updatedRedoEntries = projectRedoEntries.slice(0, -1);
        const updatedUndoEntries = [
            ...projectUndoEntries,
            { ...lastRedoEntry, versionId: currentProjectVersionId },
        ];
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    async function rollbackToVersion(versionId: string) {
        const matchingIndex = projectUndoEntries.findIndex(
            (entry) => entry.versionId === versionId,
        );
        if (matchingIndex === -1) {
            return;
        }
        const [project] = (await FetchGtmProjectFileTrigger(currentProject.name, versionId)) ?? [];
        if (!project) {
            return;
        }

        const rolledBackUndoEntries = projectUndoEntries.slice(matchingIndex);
        let updatedRedoEntries: GtmHistoryEntry[] = [];

        for (let i = 0; i < rolledBackUndoEntries.length - 1; i += 1) {
            updatedRedoEntries.unshift({
                ...rolledBackUndoEntries[i],
                versionId: rolledBackUndoEntries[i + 1].versionId,
            });
        }
        updatedRedoEntries.unshift({
            ...(rolledBackUndoEntries.at(-1) as GtmHistoryEntry),
            versionId: currentProjectVersionId,
        });
        updatedRedoEntries = [...projectRedoEntries, ...updatedRedoEntries];

        const updatedUndoEntries = projectUndoEntries.slice(0, matchingIndex);
        const modifiedProject = {
            ...project,
            history: { undoEntries: updatedUndoEntries, redoEntries: updatedRedoEntries },
        };
        dispatch(overwriteProject({ project: modifiedProject }));
        updateModelSelectionsAndVisualization(currentModelSelectedObject, project);
        syncProject(skipHistoryEntry);
    }

    /**
     * Helper function to make sure the visualization and model and object selections before and after a redo/undo/rollback operation are consistent
     * by trying to keep the current selections as long as the updated state contains matching a matching model and object
     */
    async function updateModelSelectionsAndVisualization(
        previousSelectedModelObject:
            | GtmProjectInput
            | AggregatableObject
            | GtmEvoOutputObject
            | undefined,
        newProject: GtmProject,
    ) {
        // Used to keep the camera the same as before the history operation
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { azimuth, plunge, ...restCameraState } = getEntityState('camera') as CameraState;

        if (!modelId) {
            refreshVisualizationForProject(newProject);
            dispatch(deselectSelectedObject());
            return;
        }

        // Update the model selection
        const matchingModelIndex = newProject.models.findIndex(({ id }) => modelId === id);

        if (matchingModelIndex === -1) {
            navigateToModelUrl(undefined);
            return;
        }

        const matchingModel = newProject.models.at(matchingModelIndex)!;
        dispatch(setSelectedModelIndex(matchingModelIndex));

        if (!isGtmAnalyticalModel(matchingModel)) {
            return;
        }
        refreshGtmAnalyticalModelVisualization(matchingModel);
        setStateFromSnapshot({ camera: restCameraState }, {});

        // Update the object selection
        if (!previousSelectedModelObject) {
            dispatch(deselectSelectedObject());
            return;
        }

        if (previousSelectedModelObject.id === matchingModel.aggregateGeometry.id) {
            dispatch(setCurrentModelAggregateObjectAsSelected());
            selectAndHighlightSceneObject(previousSelectedModelObject.id);
        } else {
            const matchingObjectIndex = matchingModel.objects.findIndex(
                ({ id }) => previousSelectedModelObject.id === id,
            );
            if (matchingObjectIndex !== undefined && matchingObjectIndex !== -1) {
                dispatch(setSelectedObjectIndex(matchingObjectIndex));
                selectAndHighlightSceneObject(previousSelectedModelObject.id);
            } else {
                dispatch(deselectSelectedObject());
            }
        }
    }

    const refreshGtmAnalyticalModelVisualization = (matchingModel: GtmAnalyticalModel) => {
        clearVisualizationAndIssues();
        if (modelViewTabSelection === ModelViewTabSelection.Triangulated) {
            renderTriangulatedObjects(matchingModel);
        } else if (modelViewTabSelection === ModelViewTabSelection.Parametric) {
            renderParametricObjects(matchingModel.volumes);
        }
        runDetectorsForModel(matchingModel);
    };

    const selectAndHighlightSceneObject = (viewId: ObjectId) => {
        dispatch(addOrUpdateSceneObject([viewId, { isSelected: true }]));

        addViewStatusListener({
            viewId,
            onComplete: () => {
                const sceneObject = getLatestState(sceneObjectById(viewId));
                if (sceneObject?.isSuccess) {
                    highlightSceneObject(sceneObject);
                }
            },
            onPending: () => {},
            onError: () => {},
        });
    };

    return {
        undoOperation,
        redoOperation,
        rollbackToVersion,
    };
}
