export interface ProjectPanelState {
    projectPanelMode: ProjectPanelMode;
    isSearchingWorkspace: boolean;
    workspaceSearchTerm: string;
    selectedWorkspaceObjectIds: string[];
    shouldMinimizeProjectPanel: boolean;
    shouldOpenNoProjectDialog: boolean;
    shouldShowProjectPanel: boolean;
    openUploadObjectsDialog: boolean;
    boundaryCreationState: BoundaryCreationState;
    modelViewTabSelection: ModelViewTabSelection;
    shouldOpenDetectionSettingsDialog: boolean;
}

export const enum ModelViewTabSelection {
    Triangulated,
    Parametric,
}

export const enum ProjectPanelMode {
    Project,
    Model,
    Workspace,
}

export const enum BoundaryCreationState {
    None,
    DrawRectangle,
    EnterProperties,
}

export function isCreatingBoundary(state: BoundaryCreationState) {
    return state !== BoundaryCreationState.None;
}
