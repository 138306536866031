import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

import {
    ADD_LABEL,
    DELETE_LABEL,
    RENAME_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

interface CustomMenuEntry {
    label: string;
    onClick: () => void;
    Icon: React.ComponentType<SvgIconProps>;
}

export function PanelItemMenu({
    anchorEl,
    onClose,
    onDelete,
    onRename,
    onAdd,
    customEntries,
}: {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onDelete?: () => void;
    onRename?: () => void;
    onAdd?: () => void;
    customEntries?: CustomMenuEntry[];
}) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            onClick={onClose}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {customEntries?.map(({ label, onClick, Icon }) => (
                <MenuItem key={label} onClick={onClick} dense>
                    <ListItemIcon>
                        <Icon fontSize="small" />
                    </ListItemIcon>
                    {label}
                </MenuItem>
            ))}
            {onAdd && (
                <MenuItem onClick={onAdd} dense>
                    <ListItemIcon>
                        <AddIcon fontSize="small" />
                    </ListItemIcon>
                    {ADD_LABEL}
                </MenuItem>
            )}
            {onRename && (
                <MenuItem onClick={onRename} dense divider>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    {RENAME_LABEL}
                </MenuItem>
            )}
            {onDelete && (
                <MenuItem onClick={onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    {DELETE_LABEL}
                </MenuItem>
            )}
        </Menu>
    );
}
