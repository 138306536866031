import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const CrossSectionIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8882 4.52867C12.0544 4.44556 12.25 4.56643 12.25 4.75228V6.00003V6.75002H13.75V6.00003V4.75228C13.75 3.45136 12.381 2.60524 11.2174 3.18703L3.21738 7.18703C2.62451 7.48346 2.25 8.08942 2.25 8.75227V16.5532C2.25 17.8383 3.58881 18.6851 4.74991 18.1344L7.82139 16.6777L8.49904 16.3563L7.85626 15.001L7.17861 15.3224L4.10713 16.7791C3.94126 16.8578 3.75 16.7368 3.75 16.5532V8.75227C3.75 8.65758 3.8035 8.57102 3.8882 8.52867L11.8882 4.52867ZM19.8771 7.62983C20.0437 7.5357 20.25 7.65611 20.25 7.84751V15.5505C20.25 15.6407 20.2015 15.7239 20.1229 15.7682L12.6229 20.0044C12.4563 20.0985 12.25 19.9781 12.25 19.7867V12.0837C12.25 11.9935 12.2985 11.9103 12.3771 11.866L19.8771 7.62983ZM21.75 7.84751C21.75 6.5077 20.3059 5.66486 19.1394 6.32377L11.6394 10.5599C11.0898 10.8703 10.75 11.4525 10.75 12.0837V19.7867C10.75 21.1265 12.1941 21.9693 13.3606 21.3104L20.8606 17.0743C21.4102 16.7639 21.75 16.1817 21.75 15.5505V7.84751Z"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
