import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

import type { GtmHistoryOperation } from 'src/gtmProject';
import { useAppDispatch } from 'src/store/store';
import type { SkipHistoryEntry } from 'src/store/thunks';
import { syncProjectThunk, skipHistoryEntry } from 'src/store/thunks';
import { ERROR_UPLOADING_PROJECT } from 'src/strings';

// The purpose of this hook is to dump the state of the project slice of the store to the file service.
// This pattern exists to ensure consistency between the application state and what we persist to file.
//
// This pattern facilitates a concern: What if the upload to the file service fails?
//    This would cause there to be a period of time where the application state and the persisted state are out of sync.
//    If a user were to refresh the page during this time, they would lose their work.
//    We have been referring to this pattern as "optimistic update" - "optimistic" because we assume the upload will succeed.
//    Conversely, a "pessimistic update" would be to wait for the upload to succeed before updating the application state,
//    and would prevent the application from updating if the upload failed. The rationale for the optimistic approach is that
//    it's a better user experience to allow the user to continue working, even if the upload fails, as we can retry the upload
//    in the background or ask the user to retry later. The assumption, again, is that upload failures are rare.
//
//
// Call `syncProject` whenever a state change or batch of state changes should be persisted.
export function useProjectSynchronizer() {
    const dispatch = useAppDispatch();
    const { addMessage } = useMessagesContext();

    return {
        syncProject: async (gtmHistoryOperation: GtmHistoryOperation | SkipHistoryEntry) => {
            try {
                await dispatch(syncProjectThunk(gtmHistoryOperation));
            } catch (error) {
                addMessage({
                    message: ERROR_UPLOADING_PROJECT,
                    type: NotificationType.ERROR,
                });
                trackError(`Error: ${error} uploading new version of project`);
                console.error(error);
            }
        },
    };
}

export type { SkipHistoryEntry };
export { skipHistoryEntry };
