import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const VolumeIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <mask
                id="mask0_5305_51320"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="2"
                width="20"
                height="20"
            >
                <path
                    d="M8.65202 3.68497L3.24939 10.4383C2.51895 11.3513 2.51895 12.6487 3.24939 13.5617L8.94581 20.6823C9.58283 21.4785 10.6396 21.8081 11.6164 21.5151L18.5615 19.4316C19.4468 19.166 20.1133 18.4336 20.2945 17.5273L21.6499 10.7503C21.8594 9.7032 21.3806 8.63866 20.4582 8.10059L11.8639 3.08726C10.7931 2.46266 9.4264 2.71701 8.65202 3.68497Z"
                    fill="#3BA9EF"
                />
            </mask>
            <g mask="url(#mask0_5305_51320)">
                <path d="M14 10.5L10 2L22 9L14 10.5Z" fill="#47B6F2" />
                <path d="M10 2L14 10.5L20 19L22 9L10 2Z" fill="#47B6F2" />
                <path d="M14 10.5L20 19L10 22L14 10.5Z" fill="#379BE0" />
                <path d="M14 10.5L10 2L2 12L14 10.5Z" fill="#3BA9EF" />
                <path d="M14 10.5L2 12L10 22L14 10.5Z" fill="#3288CB" />
                <path d="M14 10.5L10 2L22 9L14 10.5Z" fill="#5FC3F4" />
            </g>
        </g>
    </SvgIcon>
);
