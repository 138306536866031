import type { GtmMeshDetectionData } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { assert } from 'src/utils/gtmAssert';
import { IssueListItem } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssueListItem';
import type { IssueAccordionItemProps } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssueVirtualizedList';
import { IssueVirtualizedList } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/IssueVirtualizedList';
import { FillSelectedHole } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/FillSelectedHole';

interface DefectAccordionProps {
    // eslint-disable-next-line react/no-unused-prop-types
    idBase: string;
    action: GtmMeshDetectorAction;
    // eslint-disable-next-line react/no-unused-prop-types
    data?: GtmMeshDetectionData;
}

export const AccordionForDefects = (props: DefectAccordionProps) => {
    // eslint-disable-next-line react/destructuring-assignment
    switch (props.action) {
        case GtmMeshDetectorAction.DetectHoles:
            return AccordionForHoles(props);
        case GtmMeshDetectorAction.DetectNonPartitioningSurfaces:
            return AccordionForNonPartitioningSurfaces(props);
        case GtmMeshDetectorAction.DetectDegenerateTris:
        case GtmMeshDetectorAction.DetectDuplicateTris:
        case GtmMeshDetectorAction.DetectInconsistentlyOrientedTris:
        case GtmMeshDetectorAction.DetectSelfIntersections:
            return AccordionForTriangles(props);
        case GtmMeshDetectorAction.DetectDuplicatePoints:
            return AccordionForPoints(props);
        default:
            assert(
                false,
                'Issue accordion component specialization not implemented for issue type.',
            );
            return null;
    }
};

// AccordionForHoles is a bit of a misnomer since we purposely keep it as a flat list.
// ie: we want:
// Holes (6) [icon]
//      Hole 1
//      Hole 2 ...
// instead of:
// Holes (6) [icon]
//   [anotherIcon] Holes (6)
//      Hole 1
//      Hole 2 ...
const AccordionForHoles = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (!data || data?.length === 0) {
        return null;
    }
    return (
        <IssueVirtualizedList
            key={`${accordionIdBase}-holes`}
            items={createHoleItems(`${accordionIdBase}-holes`, data, action)}
        />
    );
};

const AccordionForNonPartitioningSurfaces = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (!data || data?.length === 0) {
        return null;
    }
    return (
        <IssueVirtualizedList
            key={`${accordionIdBase}-nonpartsurfs`}
            items={createNonPartitioningSurfaceItems(
                `${accordionIdBase}-nonpartsurfs`,
                data,
                action,
            )}
        />
    );
};

const AccordionForTriangles = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (!data || data?.length === 0) {
        return null;
    }
    // Defect data array should have 1 entry with many triangles
    const defectData = data[0];
    return (
        <IssueVirtualizedList
            key={`${accordionIdBase}-triangles`}
            items={createTriangleItems(`${accordionIdBase}-triangles`, defectData, action)}
        />
    );
};

const AccordionForPoints = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (!data || data?.length === 0) {
        return null;
    }
    // Defect data array should have 1 entry with many points
    const defectData = data[0];
    return (
        <IssueVirtualizedList
            key={`${accordionIdBase}-triangles`}
            items={createPointItems(`${accordionIdBase}-triangles`, defectData, action)}
        />
    );
};

function createPointItems(
    idBase: string,
    defect: GtmMeshDetectionData[number],
    action: GtmMeshDetectorAction,
): IssueAccordionItemProps[] {
    return defect.points.map((pointId: number) => ({
        id: `${idBase}-point-${pointId}`,
        LeafElementType: IssueListItem,
        leafElementProps: {
            artifactName: `Point ${pointId}`,
            artifactIndex: pointId,
            action,
        },
    }));
}

function createTriangleItems(
    idBase: string,
    defect: GtmMeshDetectionData[number],
    action: GtmMeshDetectorAction,
): IssueAccordionItemProps[] {
    return defect.triangles.map((triangleId: number) => ({
        id: `${idBase}-triangle-${triangleId}`,
        LeafElementType: IssueListItem,
        leafElementProps: {
            artifactName: `Triangle ${triangleId}`,
            artifactIndex: triangleId,
            action,
        },
    }));
}

function createHoleItems(
    idBase: string,
    holeDefects: GtmMeshDetectionData,
    action: GtmMeshDetectorAction,
): IssueAccordionItemProps[] {
    return holeDefects.map((_hole, holeIndex) => ({
        id: `${idBase}-hole-${holeIndex}`,
        LeafElementType: IssueListItem,
        leafElementProps: {
            artifactName: `Hole ${holeIndex}`,
            artifactIndex: holeIndex,
            action,
            renderAdditionalActions: () => <FillSelectedHole holeIndex={holeIndex} />,
        },
    }));
}

function createNonPartitioningSurfaceItems(
    idBase: string,
    nonPartSurfDefects: GtmMeshDetectionData,
    action: GtmMeshDetectorAction,
): IssueAccordionItemProps[] {
    return nonPartSurfDefects.map((_, index) => ({
        id: `${idBase}-NonPartitioningSurface-${index}`,
        LeafElementType: IssueListItem,
        leafElementProps: {
            artifactName: `Non-partitioning surface ${index}`,
            artifactIndex: index,
            action,
        },
    }));
}
