import type {
    GeoscienceObject as GooseGeoscienceObject,
    UpdateGeoscienceObject as UpdateGooseGeoscienceObject,
} from '@api/goose/dist/enhancedGooseClient';
import {
    useEnhancedPostObjectsMutation,
    useEnhancedUpdateObjectByIdMutation,
    useLazyGetObjectByIdQuery,
} from '@api/goose/dist/enhancedGooseClient';
import { useTrace } from '@local/web-design-system-2';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { waitForMs } from 'src/apiClients/file/utils';
import { ParametricIcon } from 'src/assets/ParametricIcon';
import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { PercentProgress } from 'src/components/PercentProgress/PercentProgress';
import { DEFAULT_MODEL_SETTINGS } from 'src/constants';
import type { GtmEvoOutputObject, GtmParametrizedGeometryModel } from 'src/gtmProject';
import { GtmModelType } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { selectWorkspaceName } from 'src/store/evo/selectors';
import {
    addModelToCurrentProject,
    updateModelAtIndexForCurrentProject,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModelCrossSections,
    selectCurrentParametricGeometries,
    selectCurrentProjectModels,
    selectCurrentProjectName,
    selectCurrentProjectParameterizedVolumes,
    selectCurrentProjectVolumes,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { CANCEL_LABEL, CLOSE_LABEL } from 'src/strings';
import type { ObjectId, VersionId } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { GO_TO_MODEL } from 'src/visualization/CrossSectionPanel/CrossSectionPanel.constants';
import {
    BACK_LABEL,
    CONFIRM_PUBLISH_LABEL,
    ENTER_NAME_PLACEHOLDER,
    getAddParametricModelDescription,
    getModelAlreadyExistsTitle,
    getModelPublishFailedTitle,
    getModelPublishSuccessTitle,
    getModifyParametricModelDescription,
    getPublishingMessage,
    getPublishingModelTitle,
    getPublishingToWorkspaceTitle,
    MODEL_ALREADY_EXISTS_MESSAGE,
    PUBLISH_LABEL,
} from 'src/visualization/ProjectPanel/components/PublishVolumesModal/PublishVolumesModal.constants';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';

const enum ModalStatus {
    NameInput,
    OverwriteConfirmation,
    Publishing,
    Success,
    Failed,
}

interface PublishVolumesModalProps {
    shouldOpen: boolean;
    closeModal: () => void;
}

export const PublishVolumesModal = ({
    shouldOpen,
    closeModal,
}: Readonly<PublishVolumesModalProps>) => {
    const workspaceName = useAppSelector(selectWorkspaceName);
    const [name, setName] = useState('');
    const [modalStatus, setModalStatus] = useState(ModalStatus.NameInput);

    return (
        <ActionDialog
            open={shouldOpen}
            icon={getIcon(modalStatus)}
            title={getTitle(modalStatus, name, workspaceName)}
            message={getMessage(modalStatus, workspaceName)}
            additionalContent={
                <AdditionalContent status={modalStatus} name={name} setName={setName} />
            }
            actions={
                <Actions
                    name={name}
                    setName={setName}
                    status={modalStatus}
                    setModalStatus={setModalStatus}
                    closeModal={closeModal}
                />
            }
            disableRestoreFocus
        />
    );
};

const AdditionalContent = ({
    status,
    name,
    setName,
}: Readonly<{ status: ModalStatus; name: string; setName: (newName: string) => void }>) => {
    switch (status) {
        case ModalStatus.NameInput:
            return <NameInput name={name} setName={setName} />;
        case ModalStatus.Publishing:
        case ModalStatus.Success:
            return <FakeProgressBar status={status} />;
        default:
            return undefined;
    }
};

const Actions = ({
    name,
    setName,
    status,
    setModalStatus,
    closeModal,
}: Readonly<{
    name: string;
    setName: (newName: string) => void;
    status: ModalStatus;
    setModalStatus: (status: ModalStatus) => void;
    closeModal: () => void;
}>) => {
    const { doesModelAlreadyExist, publishVolumes, publishedModelUuid, abortPublishing } =
        usePublishVolumes(name, setModalStatus);

    switch (status) {
        case ModalStatus.NameInput:
            return (
                <NameInputActions
                    name={name}
                    setName={setName}
                    doesModelAlreadyExist={doesModelAlreadyExist}
                    publishVolumes={publishVolumes}
                    setModalStatus={setModalStatus}
                    closeModal={closeModal}
                />
            );
        case ModalStatus.OverwriteConfirmation:
            return (
                <OverwriteConfirmationActions
                    publishVolumes={publishVolumes}
                    setModalStatus={setModalStatus}
                />
            );
        case ModalStatus.Publishing:
            return (
                <PublishingActions
                    abortPublishing={abortPublishing}
                    setModalStatus={setModalStatus}
                    closeModal={closeModal}
                    setName={setName}
                />
            );
        case ModalStatus.Success:
            return (
                <SuccessActions
                    setName={setName}
                    publishedModelUuid={publishedModelUuid}
                    setModalStatus={setModalStatus}
                    closeModal={closeModal}
                />
            );
        case ModalStatus.Failed:
            return <FailedActions setModalStatus={setModalStatus} closeModal={closeModal} />;
        default:
            return undefined;
    }
};

const ICON_SX = { fontSize: '35px' };
const getIcon = (status: ModalStatus) => {
    switch (status) {
        case ModalStatus.NameInput:
        case ModalStatus.Publishing:
        case ModalStatus.Success:
            return <ParametricIcon sx={ICON_SX} />;
        case ModalStatus.Failed:
            return <WarningIcon color="error" sx={ICON_SX} />;
        case ModalStatus.OverwriteConfirmation:
            return <WarningIcon color="warning" sx={ICON_SX} />;
        default:
            return undefined;
    }
};

const getTitle = (status: ModalStatus, modelName: string, workspaceName?: string) => {
    switch (status) {
        case ModalStatus.NameInput:
            return getPublishingToWorkspaceTitle(workspaceName ?? 'workspace');
        case ModalStatus.OverwriteConfirmation:
            return getModelAlreadyExistsTitle(modelName);
        case ModalStatus.Publishing:
            return getPublishingModelTitle(modelName);
        case ModalStatus.Success:
            return getModelPublishSuccessTitle(modelName);
        case ModalStatus.Failed:
            return getModelPublishFailedTitle(modelName);
        default:
            return PUBLISH_LABEL;
    }
};

const getMessage = (status: ModalStatus, workspaceName?: string) => {
    switch (status) {
        case ModalStatus.OverwriteConfirmation:
            return MODEL_ALREADY_EXISTS_MESSAGE;
        case ModalStatus.Publishing:
            return getPublishingMessage(workspaceName ?? 'workspace');
        default:
            return undefined;
    }
};

const NameInput = ({
    name,
    setName,
}: Readonly<{ name: string; setName: (newName: string) => void }>) => {
    const applyTrace = useTrace('publish-volumes-modal-name-input');

    return (
        <TextField
            automation-id={applyTrace()}
            autoFocus
            sx={{ mt: 2, mb: 2 }}
            size="small"
            placeholder={ENTER_NAME_PLACEHOLDER}
            value={name}
            onChange={(e) => {
                setName(e.target.value);
            }}
        />
    );
};

const NameInputActions = ({
    name,
    setName,
    doesModelAlreadyExist,
    publishVolumes,
    setModalStatus,
    closeModal,
}: Readonly<{
    name: string;
    setName: (newName: string) => void;
    doesModelAlreadyExist: boolean;
    publishVolumes: () => void;
    setModalStatus: (status: ModalStatus) => void;
    closeModal: () => void;
}>) => {
    const applyTrace = useTrace('publish-volumes-modal-name-input-actions');

    const handleCancel = () => {
        setName('');
        closeModal();
    };

    const handlePublishOnClick = () => {
        if (doesModelAlreadyExist) {
            setModalStatus(ModalStatus.OverwriteConfirmation);
            return;
        }
        publishVolumes();
    };

    return (
        <Stack direction="row" sx={{ ml: 'auto' }} spacing={1}>
            <Button automation-id={applyTrace('cancel')} size="small" onClick={handleCancel}>
                {CANCEL_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('publish')}
                size="small"
                variant="contained"
                startIcon={<CheckIcon />}
                disabled={!name}
                onClick={handlePublishOnClick}
            >
                {PUBLISH_LABEL}
            </Button>
        </Stack>
    );
};

const OverwriteConfirmationActions = ({
    publishVolumes,
    setModalStatus,
}: Readonly<{
    publishVolumes: () => void;
    setModalStatus: (status: ModalStatus) => void;
}>) => {
    const applyTrace = useTrace('publish-volumes-modal-overwrite-confirmation-actions');

    const handlePublishOnClick = () => {
        publishVolumes();
    };

    return (
        <>
            <Button
                automation-id={applyTrace('back')}
                size="small"
                startIcon={<ChevronLeftIcon />}
                onClick={() => setModalStatus(ModalStatus.NameInput)}
            >
                {BACK_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('confirm-publish')}
                size="small"
                variant="contained"
                onClick={handlePublishOnClick}
            >
                {CONFIRM_PUBLISH_LABEL}
            </Button>
        </>
    );
};

const PublishingActions = ({
    abortPublishing,
    setModalStatus,
    closeModal,
    setName,
}: Readonly<{
    abortPublishing: () => void;
    setModalStatus: (status: ModalStatus) => void;
    closeModal: () => void;
    setName: (newName: string) => void;
}>) => {
    const applyTrace = useTrace('publish-volumes-modal-publishing-actions');

    const handleCancel = () => {
        closeModal();
        abortPublishing();
        setModalStatus(ModalStatus.NameInput);
        setName('');
    };

    return (
        <Button
            automation-id={applyTrace('cancel')}
            sx={{ m: 'auto' }}
            size="small"
            onClick={handleCancel}
            color="info"
        >
            {CANCEL_LABEL}
        </Button>
    );
};

const FailedActions = ({
    setModalStatus,
    closeModal,
}: Readonly<{
    setModalStatus: (status: ModalStatus) => void;
    closeModal: () => void;
}>) => {
    const applyTrace = useTrace('publish-volumes-modal-failed-actions');

    return (
        <Button
            automation-id={applyTrace('close')}
            size="small"
            sx={{ m: 'auto' }}
            onClick={() => {
                closeModal();
                setModalStatus(ModalStatus.NameInput);
            }}
        >
            {CLOSE_LABEL}
        </Button>
    );
};

const SuccessActions = ({
    publishedModelUuid,
    setModalStatus,
    closeModal,
    setName,
}: Readonly<{
    publishedModelUuid: string | null;
    setModalStatus: (status: ModalStatus) => void;
    closeModal: () => void;
    setName: (newName: string) => void;
}>) => {
    const applyTrace = useTrace('publish-volumes-modal-success-actions');
    const { navigateToModelUrl } = useGtmNavigator();

    const handleClose = () => {
        closeModal();
        setName('');
        setModalStatus(ModalStatus.NameInput);
    };

    const handleGoToModel = () => {
        closeModal();
        setName('');
        setModalStatus(ModalStatus.NameInput);
        if (publishedModelUuid) {
            navigateToModelUrl(publishedModelUuid);
        }
    };

    return (
        <Stack direction="row" spacing={1} ml="auto">
            <Button automation-id={applyTrace('close')} size="small" onClick={handleClose}>
                {CLOSE_LABEL}
            </Button>
            <Button
                automation-id={applyTrace('go-to-model')}
                size="small"
                variant="outlined"
                startIcon={<OpenInNewIcon />}
                onClick={handleGoToModel}
            >
                {GO_TO_MODEL}
            </Button>
        </Stack>
    );
};

const FakeProgressBar = ({ status }: Readonly<{ status: ModalStatus }>) => {
    const applyTrace = useTrace('publish-volumes-modal-fake-progress-bar');
    const [progress, setProgress] = useState(0);
    const intervalIdRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

    useEffect(() => {
        if (status === ModalStatus.Success) {
            setProgress(100);
            clearInterval(intervalIdRef.current);
        } else {
            intervalIdRef.current = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = Math.random() * 30;
                    return Math.min(oldProgress + diff, 90);
                });
            }, 300);
        }

        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, [status]);

    return (
        <Box automation-id={applyTrace()} mt={2}>
            <PercentProgress percent={progress} showPercentText />
        </Box>
    );
};

const usePublishVolumes = (name: string, setModalStatus: (status: ModalStatus) => void) => {
    const dispatch = useAppDispatch();
    const currentModels = useAppSelector(selectCurrentProjectModels);
    const currentProjectName = useAppSelector(selectCurrentProjectName);
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const currentParameterizedVolumes = useAppSelector(selectCurrentProjectParameterizedVolumes);
    const currentCrossSections = useAppSelector(selectCurrentModelCrossSections);
    const currentParametricGeometries = useAppSelector(selectCurrentParametricGeometries);
    const [GetGooseObjectTrigger] = useLazyGetObjectByIdQuery();
    const [CreateObjectTrigger] = useEnhancedPostObjectsMutation();
    const [UpdateObjectTrigger] = useEnhancedUpdateObjectByIdMutation();
    const { syncProject } = useProjectSynchronizer();
    const gooseContext = useGooseContext();
    const publishedModelUuidRef = useRef<string | null>(null);
    // TODO: use the abort controller to cancel goose requests
    const abortControllerRef = useRef(new AbortController());
    const { doesModelAlreadyExist, matchingParametrizedGeometryModelIndex } = useMemo(() => {
        const matchingIndex = currentModels.findIndex(
            (model) => model.type === GtmModelType.ParametrizedGeometry && model.name === name,
        );
        return {
            doesModelAlreadyExist: matchingIndex > -1,
            matchingParametrizedGeometryModelIndex: matchingIndex,
        };
    }, [currentModels, name]);

    const fetchDesignGeometryObject = async (designGeometry: GtmEvoOutputObject) => {
        const { data: objectData, isSuccess: gooseObjectSuccess } = await GetGooseObjectTrigger({
            objectId: designGeometry.id,
            version: designGeometry.version,
            workspaceId: gooseContext!.workspaceId,
            orgId: gooseContext!.orgId,
        });

        if (!gooseObjectSuccess || !objectData) {
            throw new Error('Failed to fetch design geometry data');
        }
        return objectData.object;
    };

    const copyDesignGeometry = async (newPath: string, designGeometry: GtmEvoOutputObject) => {
        const object = await fetchDesignGeometryObject(designGeometry);

        // `uuid` must be set to `null` to create a *new* object, otherwise the Goose call will
        // create a new version of the existing object.
        const newObject = { ...object, name, uuid: null } as GooseGeoscienceObject;
        const response = await CreateObjectTrigger({
            orgId: gooseContext!.orgId,
            workspaceId: gooseContext!.workspaceId,
            geoscienceObject: newObject,
            path: newPath,
        });
        let { data: result } = response;
        const conflictError = response.error as
            | { status?: number; data?: { existing_id?: string } }
            | undefined;

        // If the design geometry exists in the same path, this means that the models in the project are not in sync with
        // the exported objects. This could happen if the user "aborted" publishing, closed the app before the sync
        // was completed or if the parametric model was deleted from the project but the design geometry was not deleted
        // from the workspace.
        if (conflictError?.status === 409 && conflictError.data?.existing_id) {
            newObject.uuid = conflictError.data.existing_id;
            result = await UpdateObjectTrigger({
                orgId: gooseContext!.orgId,
                workspaceId: gooseContext!.workspaceId,
                geoscienceObject: newObject as UpdateGooseGeoscienceObject,
                objectId: conflictError.data?.existing_id,
            }).unwrap();
        }

        if (!result || !result.version_id || !result.object_id) {
            throw new Error('Failed to copy volumes design geometry');
        }

        return decorateNewObject(
            { id: result.object_id as ObjectId, version: result.version_id as VersionId },
            name,
            result.object.schema,
            false,
        );
    };

    const overwriteDesignGeometry = async (designGeometry: GtmEvoOutputObject) => {
        const object = await fetchDesignGeometryObject(designGeometry);
        const matchingModel = currentModels[
            matchingParametrizedGeometryModelIndex
        ] as GtmParametrizedGeometryModel;
        const existingParameterizedVolumes = matchingModel.parameterizedVolumes;

        const updatedObject = { ...object, name, uuid: existingParameterizedVolumes.id };
        const result = await UpdateObjectTrigger({
            orgId: gooseContext!.orgId,
            workspaceId: gooseContext!.workspaceId,
            geoscienceObject: updatedObject,
            objectId: existingParameterizedVolumes.id,
        }).unwrap();

        if (!result || !result.version_id || !result.object_id) {
            throw new Error('Failed to overwrite volumes design geometry');
        }

        return decorateNewObject(
            { id: result.object_id as ObjectId, version: result.version_id as VersionId },
            name,
            result.object.schema,
            false,
        );
    };

    const addNewModelToProject = (newParametrizedObject: GtmEvoOutputObject) => {
        const newModelUuid = uuidv4();
        publishedModelUuidRef.current = newModelUuid;
        const newParameterizedGeometryModel: GtmParametrizedGeometryModel = {
            type: GtmModelType.ParametrizedGeometry,
            id: newModelUuid,
            name,
            settings: DEFAULT_MODEL_SETTINGS,
            parameterizedVolumes: newParametrizedObject,
            volumes: currentVolumes,
            crossSections: currentCrossSections,
            parametricGeometries: currentParametricGeometries,
        };
        dispatch(addModelToCurrentProject(newParameterizedGeometryModel));
    };

    const replaceModelInProject = (newParametrizedObject: GtmEvoOutputObject) => {
        const matchingModel = currentModels[matchingParametrizedGeometryModelIndex];
        publishedModelUuidRef.current = matchingModel.id;
        const newModelFields: Partial<GtmParametrizedGeometryModel> = {
            parameterizedVolumes: newParametrizedObject,
            volumes: currentVolumes,
        };
        dispatch(
            updateModelAtIndexForCurrentProject([
                newModelFields,
                matchingParametrizedGeometryModelIndex,
            ]),
        );
    };

    const publishVolumes = async () => {
        if (!currentParameterizedVolumes) {
            console.error('currentParameterizedVolumes is unexpectedly undefined');
            return;
        }
        setModalStatus(ModalStatus.Publishing);
        // Wait to allow a user to cancel the publishing process
        await waitForMs(1000);
        if (isAbortedAndResetIfAborted()) {
            return;
        }
        try {
            if (doesModelAlreadyExist) {
                const newParametrizedObject = await overwriteDesignGeometry(
                    currentParameterizedVolumes,
                );
                if (isAbortedAndResetIfAborted()) {
                    return;
                }
                replaceModelInProject(newParametrizedObject);
                syncProject({
                    description: getAddParametricModelDescription(name, currentProjectName),
                });
            } else {
                const newPath = `${currentProjectName}/${name}.json`;
                const newParametrizedObject = await copyDesignGeometry(
                    newPath,
                    currentParameterizedVolumes,
                );
                if (isAbortedAndResetIfAborted()) {
                    return;
                }
                addNewModelToProject(newParametrizedObject);
                syncProject({
                    description: getModifyParametricModelDescription(name, currentProjectName),
                });
            }
            setModalStatus(ModalStatus.Success);
        } catch (e) {
            setModalStatus(ModalStatus.Failed);
        }
    };

    const abortPublishing = () => {
        abortControllerRef.current.abort();
    };

    const isAbortedAndResetIfAborted = () => {
        if (abortControllerRef.current.signal.aborted) {
            abortControllerRef.current = new AbortController();
            return true;
        }
        return false;
    };

    return {
        doesModelAlreadyExist,
        publishVolumes,
        publishedModelUuid: publishedModelUuidRef.current,
        abortPublishing,
    };
};
