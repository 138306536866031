import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const AggregateIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                d="M6.5 9L3.43362 11.0442C3.08525 11.2764 2.94457 11.6666 3 12.0291M17.5 9L20.549 10.7374C21.2424 11.1336 21.2144 12.1428 20.5 12.5L12.447 16.7764C12.1655 16.9172 11.8341 16.9172 11.5526 16.7764L3.54111 12.7706C3.22883 12.6145 3.04623 12.3314 3 12.0291M3 12.0291V17.5L12 22M12 22V17M12 22L16.4388 19.5L21 17V12.0291"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path d="M15 8H13V2H11V8H9L12 12L15 8Z" fill="currentColor" />
        </g>
    </SvgIcon>
);
