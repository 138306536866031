/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice } from '@reduxjs/toolkit';

import { initialSettingsPanelState } from 'src/store/ui/settingsPanel/selectors';
import type { SettingsPanelState } from 'src/store/ui/settingsPanel/settingsPanel.types';
import { CrossSectionPanelMode } from 'src/store/ui/settingsPanel/settingsPanel.types';

export const settingsPanelSlice = createSlice({
    name: 'settingsPanel',
    initialState: initialSettingsPanelState,
    reducers: {
        switchToCrossSectionPanelDrawLineMode(settingsPanelState: SettingsPanelState) {
            settingsPanelState.crossSectionPanelMode = CrossSectionPanelMode.DrawLine;
        },
        switchToCrossSectionPanelEditPropertiesMode(settingsPanelState: SettingsPanelState) {
            settingsPanelState.crossSectionPanelMode = CrossSectionPanelMode.EditProperties;
        },
        switchToCrossSectionPanelViewPropertiesMode(settingsPanelState: SettingsPanelState) {
            settingsPanelState.crossSectionPanelMode = CrossSectionPanelMode.ViewProperties;
        },
        resetCrossSectionPanelMode(settingsPanelState: SettingsPanelState) {
            settingsPanelState.crossSectionPanelMode = CrossSectionPanelMode.None;
        },
    },
});

export const {
    switchToCrossSectionPanelDrawLineMode,
    switchToCrossSectionPanelEditPropertiesMode,
    switchToCrossSectionPanelViewPropertiesMode,
    resetCrossSectionPanelMode,
} = settingsPanelSlice.actions;
