import type { GtmModelUnion } from 'src/gtmProject';

export const NAME_LABEL = 'Name';
export const DIP_DIRECTION_LABEL = 'Dip dir.';
export const ALIGNMENT_LABEL = 'Alignment';
export const DEGREES_SIGN = '°';
export const FLIP_LABEL = 'Flip';
export const LOCATION_LABEL = 'Location';
export const EDIT_LABEL = 'Edit';
export const PUBLISH_TO_EVO_LABEL = 'Publish to Evo';
export const X_LABEL = 'X';
export const Y_LABEL = 'Y';
export const CROSS_SECTION_NAME_SUFFIX = '_cross_section';
export const DRAW_CROSS_SECTION_PROMPT =
    'Click & drag where you want to create a cross-section of your model ';
export const DEFAULT_NAME = 'New cross section';
export const getAddCrossSectionDescription = (model: GtmModelUnion, crossSectionName: string) =>
    `Add cross section "${crossSectionName}" to model "${model.name}"`;
export const getEditCrossSectionDescription = (model: GtmModelUnion, crossSectionName: string) =>
    `Edit cross section "${crossSectionName}" of model "${model.name}"`;
export const PUBLISHING_TO_EVO_MESSAGE = 'Publishing to Evo...';
export const PUBLISHING_TO_EVO_SUCCESS_MESSAGE = 'Successfully published to Evo';
export const PUBLISHING_TO_EVO_FAILED_MESSAGE = 'Publishing failed';
export const getPublishModalMessage = (crossSectionName: string, workspaceName: string) =>
    `Publishing "${crossSectionName}" to ${workspaceName}`;
export const CONFIRM_LABEL = 'Confirm';
export const GO_TO_MODEL = 'Go to model';
export const getPublishNewerVersionTitle = (crossSectionName: string) =>
    `"${crossSectionName}" already exists`;
export const PUBLISH_NEWER_VERSION_MESSAGE = 'Would you like to replace it with a newer version?';
