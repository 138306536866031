import type { GtmPoint } from 'src/gtmProject';
import { selectCoordOffset } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';

export function useCoordTransformation() {
    const offset = useAppSelector(selectCoordOffset);

    // Transform a global coordinate to local.
    // Currently it's just an offset, but in the future it may include rotation.
    function globalToLocalCoord(globalCoord: GtmPoint): GtmPoint {
        if (!offset) return globalCoord;

        const { x, y, z } = globalCoord;
        return { x: x - offset.x, y: y - offset.y, z: z - offset.z };
    }

    // Transform a local coordinate to global.
    // Currently it's just an offset, but in the future it may include rotation.
    function localToGlobalCoord(localCoord: GtmPoint): GtmPoint {
        if (!offset) return localCoord;

        const { x, y, z } = localCoord;
        return { x: x + offset.x, y: y + offset.y, z: z + offset.z };
    }

    return { globalToLocalCoord, localToGlobalCoord };
}
