import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const WorkspacesIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4699 5.09836L10.2764 5.69514C10.107 5.77983 10 5.95297 10 6.14235V8.13661L8.33333 7.30328V5.19673L10 4.36339L11.4699 5.09836ZM13.3333 4.97568V8.02432C13.3333 8.19003 13.2514 8.3433 13.1174 8.43577C13.0983 8.44898 13.0781 8.46095 13.0569 8.47153L10.7236 9.6382L10.2236 9.8882C10.0828 9.95858 9.91716 9.95858 9.77639 9.8882L6.94306 8.47153C6.77367 8.38684 6.66667 8.21371 6.66667 8.02432V4.47569C6.66667 4.2863 6.77367 4.11317 6.94306 4.02847L9.77639 2.61181C9.91716 2.54143 10.0828 2.54143 10.2236 2.61181L13.0569 4.02847C13.2263 4.11317 13.3333 4.2863 13.3333 4.47569V4.97568ZM5.83333 14.8033L5.83333 12.809C5.83333 12.6196 5.94033 12.4465 6.10973 12.3618L7.30327 11.765L5.83333 11.0301L4.16667 11.8634V13.9699L5.83333 14.8033ZM6.55694 16.3049L6.05694 16.5549C5.91618 16.6252 5.75049 16.6252 5.60973 16.5549L2.77639 15.1382C2.607 15.0535 2.5 14.8804 2.5 14.691V11.1424C2.5 10.953 2.607 10.7798 2.77639 10.6951L5.60973 9.27847C5.75049 9.20809 5.91618 9.20809 6.05694 9.27847L8.89027 10.6951C9.05967 10.7798 9.16667 10.953 9.16667 11.1424V11.6416C9.16667 11.6419 9.16667 11.6421 9.16667 11.6424L9.16667 14.691C9.16667 14.8804 9.05967 15.0535 8.89027 15.1382L6.55694 16.3049ZM14.4431 12.3618L15.6366 11.765L14.1667 11.0301L12.5 11.8634V13.9699L14.1667 14.8033L14.1667 12.809C14.1667 12.6196 14.2737 12.4465 14.4431 12.3618ZM17.5 14.691V11.6424V11.1424C17.5 10.953 17.393 10.7798 17.2236 10.6951L14.3903 9.27847C14.2495 9.20809 14.0838 9.20809 13.9431 9.27847L11.1097 10.6951C10.9403 10.7798 10.8333 10.953 10.8333 11.1424V14.691C10.8333 14.8804 10.9403 15.0535 11.1097 15.1382L13.9431 16.5549C14.0838 16.6252 14.2495 16.6252 14.3903 16.5549L14.8903 16.3049L17.2236 15.1382C17.2448 15.1276 17.265 15.1156 17.2841 15.1024C17.4181 15.01 17.5 14.8567 17.5 14.691Z"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
