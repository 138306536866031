import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const XPlaneIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                d="M12 12V2M12 12L22 17M12 12L2 17"
                stroke="#FAFCFF"
                strokeOpacity="0.56"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 4.5L7 9.5V19.5L17 14.5V4.5Z"
                fill="#3BA9EF"
                fillOpacity="0.3"
                stroke="#47B6F2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 7L12 12M2 7L12 2L22 7M2 7V17L12 22M12 12L22 7M12 12V22M22 7V17L12 22"
                stroke="#949597"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </SvgIcon>
);
