import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'src/store/store';
import type { SettingsPanelState } from 'src/store/ui/settingsPanel/settingsPanel.types';
import { CrossSectionPanelMode } from 'src/store/ui/settingsPanel/settingsPanel.types';

export const initialSettingsPanelState: SettingsPanelState = {
    crossSectionPanelMode: CrossSectionPanelMode.None,
};

type SelectorTypeCrossSectionPanelMode = (state: RootState) => CrossSectionPanelMode;
type SelectorTypeBoolean = (state: RootState) => boolean;

export const settingsPanelState = (state: RootState): SettingsPanelState => state.settingsPanel;
export const selectCrossSectionPanelMode: SelectorTypeCrossSectionPanelMode = createSelector(
    settingsPanelState,
    (settingsPanelStateRoot) => settingsPanelStateRoot.crossSectionPanelMode,
);
export const selectShouldShowCrossSectionPanel: SelectorTypeBoolean = createSelector(
    selectCrossSectionPanelMode,
    (crossSectionPanelMode) => crossSectionPanelMode !== CrossSectionPanelMode.None,
);
export const selectIsCrossSectionPanelInDrawLineMode: SelectorTypeBoolean = createSelector(
    selectCrossSectionPanelMode,
    (crossSectionPanelMode) => crossSectionPanelMode === CrossSectionPanelMode.DrawLine,
);
export const selectIsCrossSectionPanelInEditPropetiesMode: SelectorTypeBoolean = createSelector(
    selectCrossSectionPanelMode,
    (crossSectionPanelMode) => crossSectionPanelMode === CrossSectionPanelMode.EditProperties,
);
export const selectIsCrossSectionPanelInViewPropertiesMode: SelectorTypeBoolean = createSelector(
    selectCrossSectionPanelMode,
    (crossSectionPanelMode) => crossSectionPanelMode === CrossSectionPanelMode.ViewProperties,
);
