import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import {
    deselectCrossSection,
    deselectSelectedModel,
    deselectSelectedObject,
} from 'src/store/project/projectSlice';
import {
    selectCurrentProjectData,
    selectCurrentProjectModels,
    selectIsCurrentProjectEmpty,
} from 'src/store/project/selectors';
import { useAppDispatch, useGetLatestSelectorState } from 'src/store/store';
import {
    clearSelectedWorkspaceObjectIds,
    setShouldOpenNoProjectDialog,
    setShouldShowProjectPanel,
    switchToModelMode,
    switchToProjectMode,
} from 'src/store/ui/projectPanel';
import { resetCrossSectionPanelMode } from 'src/store/ui/settingsPanel';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';

export const UrlListener = () => {
    const dispatch = useAppDispatch();
    const { workspaceUuid, projectName: urlProjectName, modelId } = useParams();
    const { swapProject, swapWorkspace } = useConglomerateActionManager();
    const navigate = useNavigate();
    const { navigateToProjectURL } = useGtmNavigator();
    const { switchCurrentModel, refreshVisualizationForProject } = useConglomerateActionManager();
    const [runModelChangeEffect, setRunModelChangeEffect] = useState(0);
    const getLatestCurrentModels = useGetLatestSelectorState(selectCurrentProjectModels);
    const getLatestIsCurrentProjectEmpty = useGetLatestSelectorState(selectIsCurrentProjectEmpty);
    const getLatestProject = useGetLatestSelectorState(selectCurrentProjectData);

    useEffect(() => {
        const currentModels = getLatestCurrentModels();

        if (modelId && currentModels.length > 0) {
            const modelIndex = currentModels.findIndex((model) => model.id === modelId);
            if (modelIndex !== -1) {
                switchCurrentModel(modelIndex);
                dispatch(deselectSelectedObject());
                dispatch(deselectCrossSection());
                dispatch(switchToModelMode());
                dispatch(clearSelectedWorkspaceObjectIds());
                dispatch(resetCrossSectionPanelMode());
            }
        } else if (!getLatestIsCurrentProjectEmpty()) {
            dispatch(deselectSelectedModel());
            dispatch(deselectSelectedObject());
            dispatch(deselectCrossSection());
            dispatch(switchToProjectMode());
            dispatch(resetCrossSectionPanelMode());
            refreshVisualizationForProject(getLatestProject());
        }
    }, [modelId, runModelChangeEffect]);

    useEffect(() => {
        async function loadProjectDataOnNameChange(name: string) {
            const project = await swapProject(name);

            if (!project) {
                console.error(`Error loading project data for project ${name}`);
                navigate(-1);
            }
            // edge case for when the app is loaded with a model ID in the URL
            if (modelId) {
                setRunModelChangeEffect((prev) => prev + 1);
            }
        }

        if (urlProjectName) {
            loadProjectDataOnNameChange(urlProjectName);
        }
    }, [urlProjectName]);

    useEffect(() => {
        async function swapWorkspaceOnUuidChange() {
            const projectFiles = await swapWorkspace();

            if (projectFiles.length === 0) {
                dispatch(setShouldOpenNoProjectDialog(true));
                dispatch(setShouldShowProjectPanel(false));
            } else if (!urlProjectName) {
                navigateToProjectURL(projectFiles[0]);
            }
        }

        if (workspaceUuid) {
            swapWorkspaceOnUuidChange();
        }
    }, [workspaceUuid]);

    return null;
};
