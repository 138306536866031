import { WDSThemeProvider, Evo as EvoIcon, useTrace } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { updateSliceLocation } from '@local/webviz/dist/context/snapshots';
import type { SliceState } from '@local/webviz/dist/types/xyz';
import { distance } from '@local/webviz/dist/utilities';
import { SliceMode } from '@local/webviz/dist/xyz';
import EditIcon from '@mui/icons-material/EditOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ChangeEvent, ReactNode, JSX } from 'react';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AlignIcon } from 'src/assets/AlignIcon';
import { CrossSectionIcon } from 'src/assets/CrossSectionIcon';
import { XPlaneIcon } from 'src/assets/XPlaneIcon';
import { YPlaneIcon } from 'src/assets/YPlaneIcon';
import { DrawingModeToolbar } from 'src/components/DrawingModeToolbar/DrawingModeToolbar';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import type { CrossSection } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useXyzCameraRotationTween } from 'src/hooks/useXyzCameraRotationTween';
import {
    addCrossSectionToCurrentModel,
    deselectCrossSection,
    selectCrossSection,
    updateSelectedCrossSection,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelCrossSections,
    selectSelectedCrossSection,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import {
    resetCrossSectionPanelMode,
    selectIsCrossSectionPanelInDrawLineMode,
    selectIsCrossSectionPanelInViewPropertiesMode,
    switchToCrossSectionPanelEditPropertiesMode,
    switchToCrossSectionPanelViewPropertiesMode,
} from 'src/store/ui/settingsPanel';
import { CANCEL_LABEL, SAVE_LABEL } from 'src/strings';
import { DEFAULT_PANEL_WIDTH, DEFAULT_SETTING_FIELD_WIDTH_PX } from 'src/styles';
import { getNextSequentialName } from 'src/utils/stringHelpers';
import { CoordinatesInput } from 'src/visualization/Common/CoordinatesInput';
import type {
    Axis,
    CoordinatesDisabledState,
    CoordinatesErrorState,
    Point,
} from 'src/visualization/Common/CoordinatesInput.types';
import {
    ALIGNMENT_LABEL,
    DEFAULT_NAME,
    DEGREES_SIGN,
    DIP_DIRECTION_LABEL,
    DRAW_CROSS_SECTION_PROMPT,
    EDIT_LABEL,
    FLIP_LABEL,
    getAddCrossSectionDescription,
    getEditCrossSectionDescription,
    LOCATION_LABEL,
    NAME_LABEL,
    PUBLISH_TO_EVO_LABEL,
    X_LABEL,
    Y_LABEL,
} from 'src/visualization/CrossSectionPanel/CrossSectionPanel.constants';
import { PublishCrossSectionToEvoModal } from 'src/visualization/CrossSectionPanel/PublishCrossSectionToEvoModal';

const defaultOrigin: Point = { x: 0, y: 0, z: 0 };

const defaultErrorState: CoordinatesErrorState = {
    isErrorX: false,
    isErrorY: false,
    isErrorZ: false,
};

const defaultDisabledState: CoordinatesDisabledState = {
    isXDisabled: false,
    isYDisabled: false,
    isZDisabled: false,
};

export const SLICE_TOOL_DIP_ANGLE_DEGREES = 90;
const DEFAULT_SLICE_TOOL_MODE = SliceMode.Front;

interface CrossSectionPanelProps {
    sx?: SxProps<Theme>;
}

const enum LocationInputMode {
    CrossSectionSlider,
    EnterCoordinates,
}

export function CrossSectionPanel({ sx }: CrossSectionPanelProps) {
    const applyTrace = useTrace('cross-section-panel');
    const dispatch = useAppDispatch();
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const selectedCrossSection = useAppSelector(selectSelectedCrossSection);
    const isDrawMode = useAppSelector(selectIsCrossSectionPanelInDrawLineMode);
    const isViewMode = useAppSelector(selectIsCrossSectionPanelInViewPropertiesMode);
    const currentCrossSections = useAppSelector(selectCurrentModelCrossSections);
    const currentModel = useAppSelector(selectCurrentModel);
    const { getSliceTool, setXyzStateDirectly, addListener, clearSlice } = useBaseXyz();
    const { tweenCameraDownRotation, restoreCameraStateBeforeTween } = useXyzCameraRotationTween();
    const { syncProject } = useProjectSynchronizer();
    const [origin, setOrigin] = useState(defaultOrigin);
    const [dipAzimuthDegrees, setDipAzimuthDegrees] = useState('0');
    const [sliderPosition, setSliderPosition] = useState(0);
    const [name, setName] = useState('');
    const [originErrorState, setOriginErrorState] =
        useState<CoordinatesErrorState>(defaultErrorState);
    const [isDipAzimuthValid, setIsDipAzimuthValid] = useState(true);
    const sliceTool = getSliceTool();
    const [shouldFlipSlice, setShouldFlipSlice] = useState(false);
    const [isUsingSliderInput, setIsUsingSliderInput] = useState(true);
    const hasInputErrors =
        Object.values(originErrorState).some((error) => error) || !isDipAzimuthValid;

    useEffect(() => {
        if (isDrawMode) {
            sliceTool.enable(handleCrossSectionDrawn);
            tweenCameraDownRotation();
        }
    }, [isDrawMode]);

    useEffect(() => {
        if (selectedCrossSection) {
            updateStateAndVisualizationFromCrossSection(selectedCrossSection);
        }
    }, [selectedCrossSection]);

    useEffect(() => {
        const removeListener = addListener('slice', 'path', handlePathChange);
        return () => {
            removeListener();
            clearSlice();
        };
    }, []);

    const handleCrossSectionDrawn = (valid: boolean) => {
        if (!valid) {
            return;
        }
        setDipAzimuthDegrees(sliceTool.getDipAzimuth().toString());
        sliceTool.setDip(SLICE_TOOL_DIP_ANGLE_DEGREES);
        sliceTool.setMode(DEFAULT_SLICE_TOOL_MODE);
        dispatch(switchToCrossSectionPanelEditPropertiesMode());
        dispatch(setShouldShowProjectPanel(true));
        setName(
            getNextSequentialName(
                DEFAULT_NAME,
                currentCrossSections.map(({ name: csName }) => csName),
            ),
        );
        setOrigin(defaultOrigin);
        setShouldFlipSlice(false);
        setIsUsingSliderInput(true);
        restoreCameraStateBeforeTween();
    };

    const handlePathChange = ({ centre, line }: SliceState['path']) => {
        const [point1, point2] = line;
        const lineDistance = distance(point1, point2);
        if (lineDistance === 0) {
            return;
        }
        const centreDistance = distance(point1, centre);
        setSliderPosition(centreDistance / lineDistance);
    };

    const updateStateAndVisualizationFromCrossSection = (crossSection: CrossSection) => {
        setName(crossSection.name);
        setDipAzimuthDegrees(crossSection.dipAzimuthDegrees.toString());
        setIsUsingSliderInput(crossSection.isUsingSliderInput);
        setShouldFlipSlice(crossSection.shouldFlipSlice);
        if (!crossSection.isUsingSliderInput) {
            setOrigin(crossSection.origin);
        } else {
            setOrigin(defaultOrigin);
        }

        sliceTool.setDip(SLICE_TOOL_DIP_ANGLE_DEGREES);
        sliceTool.setDipAzimuth(crossSection.dipAzimuthDegrees);
        sliceTool.setMode(crossSection.shouldFlipSlice ? SliceMode.Back : SliceMode.Front);
        const { x, y, z } = crossSection.origin;
        const sliceSnapshot = { slice: { active: true, location: [x, y, z] } };
        setXyzStateDirectly(sliceSnapshot);
    };

    const updateOrigin = (axis: Axis, updatedValue: number) => {
        const updatedErrorState = getUpdatedErrorState(originErrorState, axis, updatedValue);
        setOriginErrorState(updatedErrorState);
        const newIntersectPoint = { ...origin, [axis]: updatedValue };
        setOrigin(newIntersectPoint);

        if (!Object.values(updatedErrorState).some((value: boolean) => value)) {
            const newLocation = [newIntersectPoint.x, newIntersectPoint.y, newIntersectPoint.z];
            setXyzStateDirectly({ slice: { location: newLocation } });
        }
    };

    const validateDipAzimuth = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.valid) {
            return null;
        }
        const valueAsNum = parseFloat(event.target.value);
        if (valueAsNum >= 0 && valueAsNum < 360) {
            return valueAsNum;
        }
        return null;
    };

    const handleDipDirectionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDipAzimuthDegrees(event.target.value);
        const validDipAzimuthAsNumber = validateDipAzimuth(event);

        if (validDipAzimuthAsNumber !== null) {
            setIsDipAzimuthValid(true);
            sliceTool.setDipAzimuth(validDipAzimuthAsNumber);
        } else {
            setIsDipAzimuthValid(false);
        }
    };

    const handleSnapDipDirectionToXAxis = () => {
        setIsDipAzimuthValid(true);
        setDipAzimuthDegrees('0');
        sliceTool.setDipAzimuth(0);
    };

    const handleSnapDipDirectionToYAxis = () => {
        setIsDipAzimuthValid(true);
        setDipAzimuthDegrees('90');
        sliceTool.setDipAzimuth(90);
    };

    const getUpdatedErrorState = (
        currentErrorState: CoordinatesErrorState,
        axis: Axis,
        updatedValue: number,
    ): CoordinatesErrorState => ({
        ...currentErrorState,
        [`isError${axis.toUpperCase()}`]: !Number.isFinite(updatedValue),
    });

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleCancel = () => {
        if (selectedCrossSection) {
            dispatch(switchToCrossSectionPanelViewPropertiesMode());
            updateStateAndVisualizationFromCrossSection(selectedCrossSection);
        } else {
            dispatch(deselectCrossSection());
            dispatch(resetCrossSectionPanelMode());
        }
    };

    const getSelectedOrigin = () => {
        if (!isUsingSliderInput) {
            return origin;
        }

        const sliceLocation = sliceTool.computeLocationOnPath(sliderPosition);
        if (!sliceLocation) {
            return defaultOrigin;
        }
        const [x, y, z] = sliceLocation;
        return { x, y, z };
    };

    const addNewCrossSection = () => {
        const newCrossSection: CrossSection = {
            id: uuidv4(),
            name,
            origin: getSelectedOrigin(),
            dipAzimuthDegrees: parseFloat(dipAzimuthDegrees),
            shouldFlipSlice,
            isUsingSliderInput,
        };
        dispatch(addCrossSectionToCurrentModel(newCrossSection));
        dispatch(selectCrossSection(currentCrossSections.length));
        dispatch(switchToCrossSectionPanelViewPropertiesMode());
        syncProject({
            description: getAddCrossSectionDescription(currentModel!, newCrossSection.name),
        });
    };

    const saveChangesToCurrentCrossSection = () => {
        dispatch(
            updateSelectedCrossSection({
                name,
                origin: getSelectedOrigin(),
                dipAzimuthDegrees: parseFloat(dipAzimuthDegrees),
                shouldFlipSlice,
                isUsingSliderInput,
            }),
        );
        dispatch(switchToCrossSectionPanelViewPropertiesMode());
        syncProject({
            description: getEditCrossSectionDescription(currentModel!, name),
        });
    };

    const handleSave = () => {
        if (selectedCrossSection) {
            saveChangesToCurrentCrossSection();
        } else {
            addNewCrossSection();
        }
    };

    const handleCancelDrawRectangle = () => {
        dispatch(resetCrossSectionPanelMode());
        dispatch(setShouldShowProjectPanel(true));
        restoreCameraStateBeforeTween();
    };

    const handleSliderChange = (_event: unknown, newValue: number | number[]) => {
        const value = newValue as number;
        setSliderPosition(value);
        const newSliceLocation = sliceTool.computeLocationOnPath(value);
        if (newSliceLocation) {
            setXyzStateDirectly(updateSliceLocation(newSliceLocation));
        }
    };

    const selectSliderAsLocationInputMode = () => {
        setIsUsingSliderInput(true);
    };

    const selectCoordinatesAsLocationInputMode = () => {
        setIsUsingSliderInput(false);
        const { x, y, z } = origin;
        setXyzStateDirectly(updateSliceLocation([x, y, z]));
    };

    const handleToggleLocationInputMode = (_: unknown, newMode: LocationInputMode) => {
        if (newMode === LocationInputMode.CrossSectionSlider) {
            selectSliderAsLocationInputMode();
        } else if (newMode === LocationInputMode.EnterCoordinates) {
            selectCoordinatesAsLocationInputMode();
        }
    };

    const handleFlipSlice = (event: ChangeEvent<HTMLInputElement>) => {
        setShouldFlipSlice(event.target.checked);
        if (event.target.checked) {
            sliceTool.setMode(SliceMode.Back);
        } else {
            sliceTool.setMode(SliceMode.Front);
        }
    };

    if (isDrawMode) {
        return (
            <DrawCrossSectionToolbar
                automation-id={applyTrace('draw-toolbar')}
                onCancel={handleCancelDrawRectangle}
            />
        );
    }

    if (isViewMode && selectedCrossSection) {
        return (
            <CrossSectionReadOnlyPanel
                sx={sx}
                sliderPosition={sliderPosition}
                crossSection={selectedCrossSection}
            />
        );
    }

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper
                    automation-id={applyTrace('root')}
                    sx={{ width: DEFAULT_PANEL_WIDTH }}
                    elevation={4}
                >
                    <Typography p={2}>{selectedCrossSection?.name ?? DEFAULT_NAME}</Typography>
                    <Divider />
                    <PanelSection title={NAME_LABEL}>
                        <TextField
                            automation-id={applyTrace('name-input')}
                            size="small"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </PanelSection>
                    <Divider />
                    <PanelSection title={ALIGNMENT_LABEL}>
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <AlignIcon />
                            <TextField
                                automation-id={applyTrace('dip-azimuth-input')}
                                label={DIP_DIRECTION_LABEL}
                                required
                                value={dipAzimuthDegrees}
                                onChange={handleDipDirectionChange}
                                type="text"
                                inputMode="decimal"
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    pattern: '[0-9]+(\\.[0-9]*)?',
                                    sx: { textAlign: 'right' },
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                margin: 0.25,
                                            }}
                                        >
                                            {DEGREES_SIGN}
                                        </InputAdornment>
                                    ),
                                }}
                                error={!isDipAzimuthValid}
                            />
                            <Stack direction="row" alignItems="center">
                                <Typography variant="caption">Snap to:</Typography>
                                <ButtonGroup sx={{ ml: 0.5 }} size="small" variant="outlined">
                                    <Button
                                        automation-id={applyTrace('snap-to-x-button')}
                                        onClick={handleSnapDipDirectionToXAxis}
                                        startIcon={<XPlaneIcon />}
                                        color={dipAzimuthDegrees === '0' ? 'primary' : 'info'}
                                    >
                                        {X_LABEL}
                                    </Button>
                                    <Button
                                        automation-id={applyTrace('snap-to-y-button')}
                                        onClick={handleSnapDipDirectionToYAxis}
                                        startIcon={<YPlaneIcon />}
                                        color={dipAzimuthDegrees === '90' ? 'primary' : 'info'}
                                    >
                                        {Y_LABEL}
                                    </Button>
                                </ButtonGroup>
                            </Stack>
                        </Stack>
                        <Stack direction="row" mt={1} alignItems="center">
                            <Typography variant="caption">{FLIP_LABEL}</Typography>
                            <Checkbox
                                automation-id={applyTrace('flip-checkbox')}
                                size="small"
                                checked={shouldFlipSlice}
                                onChange={handleFlipSlice}
                            />
                        </Stack>
                    </PanelSection>
                    <Divider />
                    <PanelSection
                        title={LOCATION_LABEL}
                        renderTitleAdditionalContent={() => (
                            <ToggleButtonGroup
                                size="small"
                                value={
                                    isUsingSliderInput
                                        ? LocationInputMode.CrossSectionSlider
                                        : LocationInputMode.EnterCoordinates
                                }
                                exclusive
                                onChange={handleToggleLocationInputMode}
                            >
                                <ToggleButton
                                    automation-id={applyTrace('switch-to-slider-button')}
                                    value={LocationInputMode.CrossSectionSlider}
                                >
                                    <Tooltip title="Use slider">
                                        <TuneIcon fontSize="small" />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton
                                    automation-id={applyTrace('switch-to-coordinates-button')}
                                    value={LocationInputMode.EnterCoordinates}
                                >
                                    <Tooltip title="Use coordinates">
                                        <GpsFixedIcon fontSize="small" />
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                    >
                        {isUsingSliderInput ? (
                            <Slider
                                automation-id={applyTrace('slider')}
                                size="small"
                                value={sliderPosition}
                                onChange={handleSliderChange}
                                min={0}
                                max={1}
                                step={0.01}
                            />
                        ) : (
                            <CoordinatesInput
                                coordinateValues={origin}
                                onChange={updateOrigin}
                                errorState={originErrorState}
                                disabledState={defaultDisabledState}
                            />
                        )}
                    </PanelSection>
                    <Divider />
                    <Stack mt={1} direction="row" justifyContent="space-between" p={1}>
                        <Button size="small" variant="text" onClick={handleCancel}>
                            {CANCEL_LABEL}
                        </Button>
                        <Button
                            onClick={handleSave}
                            size="small"
                            variant="contained"
                            disabled={hasInputErrors}
                        >
                            {SAVE_LABEL}
                        </Button>
                    </Stack>
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}

interface CrossSectionReadOnlyPanelProps {
    sx?: SxProps<Theme>;
    sliderPosition: number;
    crossSection: CrossSection;
    hideName?: boolean;
    hideActions?: boolean;
}

export const CrossSectionReadOnlyPanel = ({
    sx,
    sliderPosition,
    crossSection,
    hideName,
    hideActions,
}: Readonly<CrossSectionReadOnlyPanelProps>) => {
    const applyTrace = useTrace('cross-section-read-only-panel');
    const dispatch = useAppDispatch();
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const [shouldOpenPublishDialog, setShouldOpenPublishDialog] = useState(false);

    const handleEdit = () => {
        dispatch(switchToCrossSectionPanelEditPropertiesMode());
    };

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper
                    automation-id={applyTrace()}
                    sx={{ width: DEFAULT_PANEL_WIDTH }}
                    elevation={4}
                >
                    {!hideName && <Typography p={2}>{crossSection.name}</Typography>}
                    <Divider />
                    <PanelSection title={ALIGNMENT_LABEL}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AlignIcon />
                            <TextField
                                automation-id={applyTrace('dip-azimuth-input')}
                                disabled
                                label={DIP_DIRECTION_LABEL}
                                value={crossSection.dipAzimuthDegrees.toString()}
                                variant="outlined"
                                size="small"
                                sx={{ width: DEFAULT_SETTING_FIELD_WIDTH_PX }}
                                inputProps={{ sx: { textAlign: 'right' } }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            sx={{
                                                margin: 0.25,
                                            }}
                                        >
                                            {DEGREES_SIGN}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Stack direction="row" mt={1} alignItems="center">
                            <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
                                {FLIP_LABEL}
                            </Typography>
                            <Checkbox
                                automation-id={applyTrace('flip-checkbox')}
                                disabled
                                size="small"
                                checked={crossSection.shouldFlipSlice}
                            />
                        </Stack>
                    </PanelSection>
                    <Divider />
                    <PanelSection title={LOCATION_LABEL}>
                        {crossSection.isUsingSliderInput ? (
                            <Slider disabled size="small" value={sliderPosition} min={0} max={1} />
                        ) : (
                            <CoordinatesInput
                                coordinateValues={crossSection.origin}
                                onChange={(_, __) => {}}
                                errorState={defaultErrorState}
                                disabledState={{
                                    isXDisabled: true,
                                    isYDisabled: true,
                                    isZDisabled: true,
                                }}
                            />
                        )}
                    </PanelSection>
                    {!hideActions && (
                        <>
                            <Divider />
                            <Stack mt={1} direction="row" justifyContent="space-between" p={1}>
                                <Button
                                    startIcon={<EditIcon />}
                                    size="small"
                                    variant="text"
                                    onClick={handleEdit}
                                    color="info"
                                >
                                    {EDIT_LABEL}
                                </Button>
                                <Button
                                    startIcon={<EvoIcon />}
                                    onClick={() => {
                                        setShouldOpenPublishDialog(true);
                                    }}
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                >
                                    {PUBLISH_TO_EVO_LABEL}
                                </Button>
                            </Stack>
                        </>
                    )}
                </Paper>
                <PublishCrossSectionToEvoModal
                    shouldOpenConfirmDialog={shouldOpenPublishDialog}
                    closeConfirmDialog={() => {
                        setShouldOpenPublishDialog(false);
                    }}
                    crossSection={crossSection}
                />
            </WDSThemeProvider>
        </Box>
    );
};

interface PanelSectionProps {
    title: string;
    children?: ReactNode;
    renderTitleAdditionalContent?: () => JSX.Element;
}

const PanelSection = ({
    title,
    children,
    renderTitleAdditionalContent,
}: Readonly<PanelSectionProps>) => (
    <Stack direction="column" mt={2} p={2} pt={0} gap={1.5}>
        <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {title}
                {renderTitleAdditionalContent?.()}
            </Stack>
        </Typography>
        {children}
    </Stack>
);

interface DrawCrossSectionToolbarProps {
    onCancel: () => void;
    'automation-id': string;
}

const DrawCrossSectionToolbar = ({
    onCancel,
    'automation-id': automationId,
}: Readonly<DrawCrossSectionToolbarProps>) => (
    <DrawingModeToolbar
        automation-id={automationId}
        onCancel={onCancel}
        icon={<CrossSectionIcon width="24" height="24" viewBox="0 0 24 24" opacity={0.56} />}
        prompt={DRAW_CROSS_SECTION_PROMPT}
    />
);
