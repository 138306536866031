import type {
    GtmAnalyticalModel,
    GtmModelUnion,
    GtmParametrizedGeometryModel,
} from 'src/gtmProject';
import { isGtmParametrizedGeometryModel, isGtmAnalyticalModel } from 'src/gtmProject';
import { assert } from 'src/utils/gtmAssert';

import type { ProjectState } from './projectSlice.types';

function getCurrentModelIfSelected(projectState: ProjectState): GtmModelUnion | undefined {
    return projectState.current.project?.models?.[projectState.current.selectedModelIndex];
}

export function getCurrentModel(projectState: ProjectState): GtmModelUnion {
    const currentModel = getCurrentModelIfSelected(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Trying to select "current" model, but no model is selected.');
    return {} as GtmModelUnion;
}

export function getCurrentModelIfAnalytical(
    projectState: ProjectState,
): GtmAnalyticalModel | undefined {
    const currentModel = getCurrentModelIfSelected(projectState);
    if (currentModel !== undefined && isGtmAnalyticalModel(currentModel)) {
        return currentModel as GtmAnalyticalModel;
    }
    return undefined;
}

export function getCurrentModelIfParameterizedGeometry(
    projectState: ProjectState,
): GtmParametrizedGeometryModel | undefined {
    const currentModel = getCurrentModelIfSelected(projectState);
    if (isGtmParametrizedGeometryModel(currentModel)) {
        return currentModel;
    }
    return undefined;
}

export function getCurrentModelIfAnalyticalOrParameterizedGeometry(
    projectState: ProjectState,
): GtmAnalyticalModel | GtmParametrizedGeometryModel | undefined {
    return (
        getCurrentModelIfAnalytical(projectState) ??
        getCurrentModelIfParameterizedGeometry(projectState)
    );
}

export function getCurrentAnalyticalModel(projectState: ProjectState) {
    const currentModel = getCurrentModelIfAnalytical(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(false, 'Current model is not an analytical model');
    return {} as GtmAnalyticalModel;
}

export function getCurrentAnalyticalOrParameterizedGeometryModel(projectState: ProjectState) {
    const currentModel = getCurrentModelIfAnalyticalOrParameterizedGeometry(projectState);
    if (currentModel) {
        return currentModel;
    }
    assert(
        false,
        'Current model is not an analytical model and not a parameterized geometry model',
    );
    return {} as GtmAnalyticalModel | GtmParametrizedGeometryModel;
}
