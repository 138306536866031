import type { GeoscienceObject } from 'src/types/core.types';

export const DELETE_MODEL_TITLE = 'Deleting Model';
export const getDeleteModelMessage = (modelName: string) =>
    `Are you sure you want to delete "${modelName}" from this project?`;
export const getDeleteProjectMessage = (projectName: string) =>
    `Are you sure you want to delete project "${projectName}"?`;
export const NEW_PROJECT_NAME = 'New Project';
export const INVALID_NEW_MODEL_NAME_MESSAGE = 'This model already exists';
export const INVALID_NEW_PROJECT_NAME_MESSAGE = 'This project already exists';
export const EMPTY_OBJECTS_MESSAGE =
    'Find and add objects from your workspace or upload them directly';
export const NO_OBJECTS_YET_MESSAGE = 'No objects yet';
export const WORKSPACE_LABEL = 'Workspace';
export const UPLOAD_LABEL = 'Upload';
export const DEFINE_ANALYTICAL_BOUNDARY_LABEL = 'Define Analytical Boundary';
export const ADDED_LABEL = 'Added';
export const ADD_LABEL = 'Add';
export const OBJECTS_LABEL = 'Objects';
export const MODELS_LABEL = 'Models';
export const CANCEL_LABEL = 'Cancel';
export const DELETE_LABEL = 'Delete';
export const RENAME_LABEL = 'Rename';
export const PROJECT_INITIALIZING_ERROR = 'Error initializing project';
export const getProjectCreationErrorMessage = (projectName: string) =>
    `Error creating project with name ${projectName}.`;
export const PROJECT_RENAME_ERROR = 'Error renaming project';
export const AT_LEAST_ONE_PROJECT_REQUIRED_ERROR = 'A workspace must contain at least one project';
export const DELETE_PROJECT_TITLE = 'Deleting Project';
export const getDeleteModelDescription = (name: string) => `Delete model "${name}"`;
export const getRenameModelDescription = (prevName: string, newName: string) =>
    `Rename model "${prevName}" to "${newName}"`;
export const getAddObjectsToProjectDescription = (objects: GeoscienceObject[]) =>
    `Add ${objects.length} object${objects.length === 1 ? '' : 's'} to project`;
export const getAddObjectToProjectDescription = (object: GeoscienceObject) =>
    `Add object "${object.name}" to project`;
export const getRemoveObjectFromProjectDescription = (object: GeoscienceObject) =>
    `Remove object "${object.name}" from project`;
export const getObjectVisibilityDescription = (
    object: GeoscienceObject,
    shouldShow: boolean,
    modelName?: string,
) =>
    `${shouldShow ? 'Show' : 'Hide'} object "${object.name}" in ${modelName ? `model "${modelName}"` : 'project'}`;
export const PUBLISH_TO_EVO_LABEL = 'Publish to Evo';
export const PARAMETERIZING_VOLUMES_LABEL = 'Parameterizing volumes...';
export const MODELS_MUST_BE_FULLY_PARAMETERIZED_LABEL =
    'One or more volumes failed parameterization. Models must be fully parameterized to publish.';
export const ADD_FROM_WORKSPACE = 'Add from workspace';
export const UPLOAD_OBJECT = 'Upload object';
export const WORKSPACE_SEARCH_PLACEHOLDER = 'Search...';
export const BACK_LABEL = 'Back';
export const getTriangulatedObjectsTabLabel = (count: number) =>
    `Triangulated${count > 0 ? ` (${count})` : ''}`;
export const getParametricObjectsTabLabel = (count: number) =>
    `Parametric${count > 0 ? ` (${count})` : ''}`;
