import type { XyzInstanceContextValue } from '@local/webviz/dist/context/createXyzInstanceContext';
import {
    XyzContext,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import type { XyzInstance } from '@local/webviz/dist/types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from 'src/store/store';
import {
    BoundaryCreationState,
    selectBoundaryCreationState,
    selectIsCreatingBoundary,
    setBoundaryCreationState,
} from 'src/store/ui/projectPanel';
import { selectIsCrossSectionPanelInDrawLineMode } from 'src/store/ui/settingsPanel';
import { ProjectPanel } from 'src/visualization/ProjectPanel/ProjectPanel';
import { SettingsPanel } from 'src/visualization/SettingsPanel/SettingsPanel';

import { BoundaryCreationDialog } from '../BoundaryCreationDialog';
import { Plot } from '../Plot/Plot';
import { Toolbar } from '../Toolbar/Toolbar';

const floatLeftPanelSx = {
    position: 'absolute',
    zIndex: 1,
    left: '16px',
    top: '16px',
};

function ContentArea(
    xyzInstanceInitialized: boolean,
    setXyzInstanceContextValue: React.Dispatch<
        React.SetStateAction<XyzInstanceContextValue | null>
    >,
) {
    const dispatch = useDispatch();
    const { workspaceUuid } = useParams();
    const shouldShowBoundaryDialog = useAppSelector(selectIsCreatingBoundary);
    const boundaryCreationState = useAppSelector(selectBoundaryCreationState);
    const isCrossSectionPanelInDrawLineMode = useAppSelector(
        selectIsCrossSectionPanelInDrawLineMode,
    );
    const shouldShowExtraPanels = !shouldShowBoundaryDialog;
    const shouldShowToolbar =
        boundaryCreationState !== BoundaryCreationState.DrawRectangle &&
        !isCrossSectionPanelInDrawLineMode;

    return (
        <>
            {xyzInstanceInitialized && workspaceUuid && (
                <>
                    <ProjectPanel sx={floatLeftPanelSx} />
                    {shouldShowExtraPanels && <SettingsPanel />}
                    {shouldShowToolbar && <Toolbar />}
                    {shouldShowBoundaryDialog && (
                        <BoundaryCreationDialog
                            sx={floatLeftPanelSx}
                            onClose={() => {
                                dispatch(setBoundaryCreationState(BoundaryCreationState.None));
                            }}
                        />
                    )}
                </>
            )}
            <Plot
                onInitialized={(xyzInstance: XyzInstance) =>
                    setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
                }
            />
        </>
    );
}

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);

    return (
        <XyzContext.Provider value={xyzInstanceContextValue}>
            {ContentArea(xyzInstanceInitialized, setXyzInstanceContextValue)}
        </XyzContext.Provider>
    );
}
