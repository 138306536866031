/* eslint-disable import/no-cycle */
import { createSelector } from '@reduxjs/toolkit';

import type {
    GtmEvoOutputObject,
    GtmProject,
    GtmAnalyticalModel,
    GtmAnalyticalModelSettings,
    GtmProjectInput,
    GtmBounds,
    AggregatableObject,
    GtmHistoryEntry,
    GtmHistory,
    GtmModelUnion,
    GtmModelSettings,
    GtmProjectSettings,
    GtmPoint,
    CrossSection,
    GtmParametrizedGeometryModel,
} from 'src/gtmProject';
import { isGtmAnalyticalModel } from 'src/gtmProject';
import type { ObjectIdWithVersion } from 'src/types/core.types';

import type { RootState } from '../store';
import type { CurrentProjectState, ProjectState } from './projectSlice.types';
import { DEFAULT_CURRENT_PROJECT_STATE } from './projectSlice.types';
import {
    getCurrentModelIfAnalytical,
    getCurrentModelIfAnalyticalOrParameterizedGeometry,
} from './projectSliceUtils';

export const initialProjectState: ProjectState = {
    current: DEFAULT_CURRENT_PROJECT_STATE,
    currentProjectVersionId: '',
    isSyncingProject: false,
};

type SelectorTypeCurrentProjectState = (state: RootState) => CurrentProjectState;
type SelectorTypeString = (state: RootState) => string;
type SelectorTypeStringArray = (state: RootState) => string[];
type SelectorTypeNumber = (state: RootState) => number;
type SelectorTypeGtmProjectData = (state: RootState) => GtmProject;
type SelectorTypeGtmOutputObject = (state: RootState) => GtmEvoOutputObject | undefined;
type SelectorTypeGtmOutputObjects = (state: RootState) => GtmEvoOutputObject[];
type SelectorTypeAggregatableObjects = (state: RootState) => AggregatableObject[];
type SelectorTypeBounds = (state: RootState) => GtmBounds | undefined;
type SelectorTypeCoordOffset = (state: RootState) => GtmPoint | undefined;
type SelectorTypeGtmAnalyticalModel = (state: RootState) => GtmAnalyticalModel | undefined;
type SelectorTypeGtmAnalyticalOrParameterizedGeometryModel = (
    state: RootState,
) => GtmAnalyticalModel | GtmParametrizedGeometryModel | undefined;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeGtmModels = (state: RootState) => GtmModelUnion[];
type SelectorTypeGtmModel = (state: RootState) => GtmModelUnion | undefined;
type SelectorTypeCurrentSelectedObject = (
    state: RootState,
) => GtmProjectInput | AggregatableObject | GtmEvoOutputObject | undefined;
type SelectorTypeGtmProjectInputs = (state: RootState) => GtmProjectInput[];
type SelectorTypeGtmModelSettings = (state: RootState) => GtmModelSettings | undefined;
type SelectorTypeAnalyticalModelSettings = (
    state: RootState,
) => GtmAnalyticalModelSettings | undefined;
type SelectorTypeGtmHistory = (state: RootState) => GtmHistory;
type SelectorTypeGtmHistoryArray = (state: RootState) => GtmHistoryEntry[];
type SelectorTypeOptionalHistoryEntry = (state: RootState) => GtmHistoryEntry | undefined;
type SelectorTypeProjectSettings = (state: RootState) => GtmProjectSettings | undefined;
type SelectorTypeCrossSections = (state: RootState) => CrossSection[];
type SelectorTypeCrossSection = (state: RootState) => CrossSection | undefined;

const projectState = (state: RootState): ProjectState => state.project ?? initialProjectState;

export const selectCurrentProject: SelectorTypeCurrentProjectState = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current,
);

export const selectCurrentProjectName: SelectorTypeString = createSelector(
    selectCurrentProject,
    (currentProject) => currentProject.project?.name ?? '',
);

export const selectCurrentProjectData: SelectorTypeGtmProjectData = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.project,
);

export const selectProjectSettings: SelectorTypeProjectSettings = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.settings,
);

export const selectCurrentProjectAnalyticalModel: SelectorTypeGtmAnalyticalModel = createSelector(
    projectState,
    (projectStateRoot) => getCurrentModelIfAnalytical(projectStateRoot),
);

export const selectCurrentProjectAnalyticalOrParameterizedGeometryModel: SelectorTypeGtmAnalyticalOrParameterizedGeometryModel =
    createSelector(projectState, (projectStateRoot) =>
        getCurrentModelIfAnalyticalOrParameterizedGeometry(projectStateRoot),
    );

export const selectCurrentProjectAnalyticalModelObjects: SelectorTypeAggregatableObjects =
    createSelector(
        selectCurrentProjectAnalyticalModel,
        (currentAnalyticalModel) => currentAnalyticalModel?.objects ?? [],
    );

export const selectCurrentAggregateGeometry: SelectorTypeGtmOutputObject = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.aggregateGeometry,
);

export const selectCurrentParametricGeometries: SelectorTypeGtmOutputObjects = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.parametricGeometries ?? [],
);

export const selectCurrentParametricGeometryNames: SelectorTypeStringArray = createSelector(
    selectCurrentParametricGeometries,
    (parameterizedGeometryList) =>
        parameterizedGeometryList.map((parameterizedGeometry) => parameterizedGeometry.name) ?? [],
);

export const selectCurrentBounds: SelectorTypeBounds = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.bounds,
);

export const selectCoordOffset: SelectorTypeCoordOffset = createSelector(
    selectCurrentBounds,
    (bounds) => bounds?.minPoint,
);

export const anAnalyticalModelIsSelected: SelectorTypeBoolean = createSelector(
    selectCurrentAggregateGeometry,
    (currentAggregateGeometry) => currentAggregateGeometry !== undefined,
);

export const selectCurrentProjectVersionId: SelectorTypeString = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.currentProjectVersionId,
);

export const selectCurrentProjectVolumes: SelectorTypeGtmOutputObjects = createSelector(
    selectCurrentProjectAnalyticalOrParameterizedGeometryModel,
    (currentModel) => currentModel?.volumes ?? [],
);

export const selectCurrentProjectParameterizedVolumes: SelectorTypeGtmOutputObject = createSelector(
    selectCurrentProjectAnalyticalOrParameterizedGeometryModel,
    (currentModel) => currentModel?.parameterizedVolumes,
);

export const selectCurrentModelCrossSections: SelectorTypeCrossSections = createSelector(
    selectCurrentProjectAnalyticalOrParameterizedGeometryModel,
    (currentModel) => currentModel?.crossSections ?? [],
);

export const selectCurrentProjectModels: SelectorTypeGtmModels = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.models ?? [],
);

export const selectCurrentProjectModelNames: SelectorTypeStringArray = createSelector(
    selectCurrentProjectModels,
    (currentModels) =>
        currentModels.map((model) => model.name).filter((name) => name !== undefined),
);

export const selectSelectedModelIndex: SelectorTypeNumber = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.selectedModelIndex,
);

export const selectCurrentModel: SelectorTypeGtmModel = createSelector(
    projectState,
    selectSelectedModelIndex,
    (projectStateRoot, selectedModelIndex) =>
        projectStateRoot.current.project?.models?.[selectedModelIndex],
);

export const selectCurrentModelName: SelectorTypeString = createSelector(
    selectCurrentModel,
    (currentModel) => currentModel?.name ?? '',
);

export const selectProjectInputObjects: SelectorTypeGtmProjectInputs = createSelector(
    selectCurrentProjectData,
    (project) => project?.inputObjects ?? [],
);

export const selectCurrentModelObjects: SelectorTypeGtmProjectInputs = createSelector(
    selectCurrentModel,
    (currentModel) => {
        if (isGtmAnalyticalModel(currentModel)) return currentModel.objects;
        return [];
    },
);

export const selectCurrentModelSettings: SelectorTypeGtmModelSettings = createSelector(
    selectCurrentModel,
    (currentModel) => currentModel?.settings,
);

export const selectCurrentAnalyticalModelSettings: SelectorTypeAnalyticalModelSettings =
    createSelector(
        selectCurrentProjectAnalyticalModel,
        (currentAnalyticalModel) => currentAnalyticalModel?.analyticalModelSettings,
    );

export const selectIsCurrentProjectEmpty: SelectorTypeBoolean = createSelector(
    selectCurrentProjectData,
    (currentProject) => Object.entries(currentProject).length === 0,
);

export const selectSelectedObjectIndex: SelectorTypeNumber = createSelector(
    selectCurrentProject,
    (currentProjectState) => currentProjectState.selectedObjectIndex,
);

export const selectIsAggregateObjectSelected: SelectorTypeBoolean = createSelector(
    selectCurrentProject,
    (currentProjectState) => currentProjectState.isAggregateObjectSelected,
);

export const selectCurrentModelSelectedObject: SelectorTypeCurrentSelectedObject = createSelector(
    selectIsAggregateObjectSelected,
    selectCurrentAggregateGeometry,
    selectCurrentProjectAnalyticalModel,
    selectCurrentProjectAnalyticalModelObjects,
    selectProjectInputObjects,
    selectSelectedObjectIndex,
    (
        isAggregateObjectSelected,
        currentAggregateGeometry,
        currentProjectAnalyticaModel,
        currentProjectAnalyticalModelObjects,
        projectInputObjects,
        selectedObjectIndex,
    ) => {
        if (isAggregateObjectSelected) {
            return currentAggregateGeometry;
        }
        if (currentProjectAnalyticaModel) {
            return currentProjectAnalyticalModelObjects[selectedObjectIndex];
        }
        return projectInputObjects[selectedObjectIndex];
    },
);

export const selectIsSyncingProject: SelectorTypeBoolean = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.isSyncingProject,
);

export const selectCurrentProjectHistory: SelectorTypeGtmHistory = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.history,
);

export const selectCurrentProjectUndoEntries: SelectorTypeGtmHistoryArray = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.history?.undoEntries ?? [],
);

export const selectCurrentProjectRedoEntries: SelectorTypeGtmHistoryArray = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.history?.redoEntries ?? [],
);

export const selectLastUndoEntry: SelectorTypeOptionalHistoryEntry = createSelector(
    selectCurrentProjectUndoEntries,
    (undoEntries) => undoEntries.at(-1),
);

export const selectLastRedoEntry: SelectorTypeOptionalHistoryEntry = createSelector(
    selectCurrentProjectRedoEntries,
    (redoEntries) => redoEntries.at(-1),
);

export const makeSelectIsObjectOutdatedOrMissing = () => {
    const selectIsObjectOutdatedOrMissing = createSelector(
        selectCurrentProjectData,
        (_state: RootState, object: ObjectIdWithVersion) => object,
        (currentProject, object) => {
            // Issue detection and transformations can only be done in analytical models.
            const analyticalModels = currentProject.models.filter((model) =>
                isGtmAnalyticalModel(model),
            );

            let objectExists = false;
            let objectIsOld = false;
            for (const model of analyticalModels) {
                const newestObject =
                    model.aggregateGeometry.id === object.id
                        ? model.aggregateGeometry
                        : model.objects.find((obj) => obj.id === object.id);

                if (newestObject) {
                    objectExists = true;
                    // Versions are UNIX timestamps, so we can compare them directly.
                    objectIsOld = Number(newestObject.version) > Number(object.version);
                    break;
                }
            }

            return !objectExists || objectIsOld;
        },
    );

    return selectIsObjectOutdatedOrMissing;
};

export const selectSelectedCrossSectionIndex: SelectorTypeNumber = createSelector(
    selectCurrentProject,
    (current) => current.selectedCrossSectionIndex,
);

export const selectSelectedCrossSection: SelectorTypeCrossSection = createSelector(
    selectCurrentModelCrossSections,
    selectSelectedCrossSectionIndex,
    (crossSections, selectedIndex) => {
        if (selectedIndex === -1) {
            return undefined;
        }
        return crossSections[selectedIndex];
    },
);
