import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { HUB_PATH, MODEL_PATH, PROJECT_PATH, WORKSPACE_PATH } from 'src/constants';

export const useGtmNavigator = () => {
    const navigate = useNavigate();
    const { orgUuid, workspaceUuid, hubCode, projectName } = useParams();

    const navigateToProjectURL = useCallback(
        (navigateProjectName: string | undefined) => {
            const projectURL = navigateProjectName ? `${PROJECT_PATH}/${navigateProjectName}` : '';
            navigate(
                `/${orgUuid}${HUB_PATH}/${hubCode}${WORKSPACE_PATH}/${workspaceUuid}${projectURL}`,
            );
        },
        [orgUuid, hubCode, workspaceUuid, navigate],
    );

    const navigateToModelUrl = useCallback(
        (modelId: string | undefined) => {
            const modelURL = modelId ? `${MODEL_PATH}/${modelId}` : '';
            navigate(
                `/${orgUuid}${HUB_PATH}/${hubCode}${WORKSPACE_PATH}/${workspaceUuid}${PROJECT_PATH}/${projectName}${modelURL}`,
            );
        },
        [orgUuid, hubCode, workspaceUuid, projectName, navigate],
    );

    return { navigateToProjectURL, navigateToModelUrl };
};
