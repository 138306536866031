/* eslint no-param-reassign: ["error", { "props": false }] */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
    ModelViewTabSelection,
    ProjectPanelState,
} from 'src/store/ui/projectPanel/projectPanel.types';
import {
    BoundaryCreationState,
    ProjectPanelMode,
} from 'src/store/ui/projectPanel/projectPanel.types';
import { initialProjectPanelState } from 'src/store/ui/projectPanel/selectors';

export const projectPanelSlice = createSlice({
    name: 'projectPanel',
    initialState: initialProjectPanelState,
    reducers: {
        switchToProjectMode(state: ProjectPanelState) {
            state.projectPanelMode = ProjectPanelMode.Project;
        },
        switchToModelMode(state: ProjectPanelState) {
            state.projectPanelMode = ProjectPanelMode.Model;
        },
        switchToWorkspaceMode(state: ProjectPanelState) {
            state.projectPanelMode = ProjectPanelMode.Workspace;
        },
        setIsSearchingWorkspaceObjects(
            state: ProjectPanelState,
            { payload: newIsSearchingWorkspaceObjects }: PayloadAction<boolean>,
        ) {
            state.isSearchingWorkspace = newIsSearchingWorkspaceObjects;
        },
        setWorkspaceSearchTerm(
            state: ProjectPanelState,
            { payload: newSearchTerm }: PayloadAction<string>,
        ) {
            state.workspaceSearchTerm = newSearchTerm;
        },
        addToSelectedWorkspaceObjectIds(
            state: ProjectPanelState,
            { payload: objectId }: PayloadAction<string>,
        ) {
            state.selectedWorkspaceObjectIds.push(objectId);
        },
        removeFromSelectedWorkspaceObjectIds(
            state: ProjectPanelState,
            { payload: objectId }: PayloadAction<string>,
        ) {
            state.selectedWorkspaceObjectIds = state.selectedWorkspaceObjectIds.filter(
                (id) => id !== objectId,
            );
        },
        clearSelectedWorkspaceObjectIds(state: ProjectPanelState) {
            state.selectedWorkspaceObjectIds = [];
        },
        setSelectedWorkspaceObjectIds(
            state: ProjectPanelState,
            { payload: newSelectedWorkspaceObjectIds }: PayloadAction<string[]>,
        ) {
            state.selectedWorkspaceObjectIds = newSelectedWorkspaceObjectIds;
        },
        toggleProjectPanelMinimize(state: ProjectPanelState) {
            state.shouldMinimizeProjectPanel = !state.shouldMinimizeProjectPanel;
        },
        setShouldOpenNoProjectDialog(
            state: ProjectPanelState,
            { payload: shouldOpen }: PayloadAction<boolean>,
        ) {
            state.shouldOpenNoProjectDialog = shouldOpen;
        },
        setShouldShowProjectPanel(
            state: ProjectPanelState,
            { payload: shouldShow }: PayloadAction<boolean>,
        ) {
            state.shouldShowProjectPanel = shouldShow;
        },
        setBoundaryCreationState(
            state: ProjectPanelState,
            { payload: creationState }: PayloadAction<BoundaryCreationState>,
        ) {
            state.boundaryCreationState = creationState;
            state.shouldShowProjectPanel = creationState !== BoundaryCreationState.DrawRectangle;
        },
        setOpenUploadObjectsDialog(
            state: ProjectPanelState,
            { payload: open }: PayloadAction<boolean>,
        ) {
            state.openUploadObjectsDialog = open;
        },
        setModelViewTabSelection(
            state: ProjectPanelState,
            { payload: tabSelection }: PayloadAction<ModelViewTabSelection>,
        ) {
            state.modelViewTabSelection = tabSelection;
        },
        openDetectionSettingsDialog(state: ProjectPanelState) {
            state.shouldOpenDetectionSettingsDialog = true;
        },
        closeDetectionSettingsDialog(state: ProjectPanelState) {
            state.shouldOpenDetectionSettingsDialog = false;
        },
    },
});

export const {
    switchToProjectMode,
    switchToModelMode,
    switchToWorkspaceMode,
    setIsSearchingWorkspaceObjects,
    setWorkspaceSearchTerm,
    addToSelectedWorkspaceObjectIds,
    removeFromSelectedWorkspaceObjectIds,
    clearSelectedWorkspaceObjectIds,
    setSelectedWorkspaceObjectIds,
    toggleProjectPanelMinimize,
    setShouldOpenNoProjectDialog,
    setShouldShowProjectPanel,
    setBoundaryCreationState,
    setOpenUploadObjectsDialog,
    setModelViewTabSelection,
    openDetectionSettingsDialog,
    closeDetectionSettingsDialog,
} = projectPanelSlice.actions;
