import { useTrace } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context';
import { updateShowGrid } from '@local/webviz/dist/context/snapshots/camera';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { ColorPicker } from 'src/components/ColorPicker/ColorPicker';
import { DEFAULT_MODEL_SETTINGS } from 'src/constants';
import type { GtmModelUnion, GtmModelSettings } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelSettings,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { SettingsPanelBase } from 'src/visualization/SettingsPanel/components/SettingsPanelBase';

import {
    BACKGROUND_LABEL,
    getBackgroundColorUpdateDescription,
    getXyzAxisUpdateDescription,
    TITLE,
    OFF_LABEL,
    ON_LABEL,
    XYZ_AXIS_LABEL,
} from './ModelSettingsPanel.constants';

export function ModelSettingsPanel() {
    const applyTrace = useTrace('model-settings-panel');
    return (
        <SettingsPanelBase automationId={applyTrace('root')} title={TITLE}>
            <SettingsSection />
        </SettingsPanelBase>
    );
}

function SettingsSection() {
    return (
        <Stack direction="column" p={2} gap={1}>
            <BackgroundColorInput />
            <Divider sx={({ spacing }) => ({ margin: spacing(0, -2) })} />
            <XYZAxisToggle />
        </Stack>
    );
}

function getUpdatedSettings(model: GtmModelUnion, newSettings: Partial<GtmModelSettings>) {
    return {
        ...(model.settings ?? DEFAULT_MODEL_SETTINGS),
        ...newSettings,
    };
}

function BackgroundColorInput() {
    const dispatch = useAppDispatch();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();
    const [openColorPicker, setOpenColorPicker] = useState(false);

    const handleColorPickerOnSave = (color: string) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, { backgroundColor: color });

        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject({
            description: getBackgroundColorUpdateDescription(currentModel?.name as string, color),
        });
    };

    // TODO (GEOM-547): Change background based on the color picked
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography width={81} variant="caption" color="secondary">
                {BACKGROUND_LABEL}
            </Typography>
            <Stack
                sx={{ position: 'relative' }}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <IconButton
                    component="label"
                    size="medium"
                    onClick={() => {
                        setOpenColorPicker(true);
                    }}
                >
                    <Icon
                        sx={{
                            borderRadius: '4px',
                            backgroundColor:
                                currentModelSettings?.backgroundColor ??
                                DEFAULT_MODEL_SETTINGS.backgroundColor,
                            border: '1px solid #FAFCFF8F',
                        }}
                    />
                </IconButton>
                <Typography variant="body2" color="secondary">
                    {currentModelSettings?.backgroundColor ??
                        DEFAULT_MODEL_SETTINGS.backgroundColor}
                </Typography>
                <ColorPicker
                    sx={{ position: 'absolute', right: '180px', top: '-32px' }}
                    open={openColorPicker}
                    onSave={handleColorPickerOnSave}
                    onClose={() => {
                        setOpenColorPicker(false);
                    }}
                    initialColor={
                        currentModelSettings?.backgroundColor ??
                        DEFAULT_MODEL_SETTINGS.backgroundColor
                    }
                />
            </Stack>
        </Stack>
    );
}

function XYZAxisToggle() {
    const dispatch = useAppDispatch();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const isChecked = currentModelSettings?.showXyzAxis ?? false;
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();
    const { setXyzStateDirectly } = useBaseXyz();

    useEffect(() => {
        setXyzStateDirectly(updateShowGrid(isChecked));
    }, [isChecked]);

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!currentModel) {
            return;
        }

        const shouldShow = event.target.checked;
        const modifiedSettings = getUpdatedSettings(currentModel, {
            showXyzAxis: shouldShow,
        });
        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject({
            description: getXyzAxisUpdateDescription(currentModel?.name as string, shouldShow),
        });
    };

    const label = isChecked ? ON_LABEL : OFF_LABEL;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography width={81} variant="caption" color="secondary">
                {XYZ_AXIS_LABEL}
            </Typography>
            <FormControlLabel
                sx={{ marginRight: 0 }}
                label={<Typography variant="body2">{label}</Typography>}
                color="primary"
                control={<Switch size="small" checked={isChecked} onChange={handleChange} />}
            />
        </Stack>
    );
}
