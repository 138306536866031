import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import ShowIcon from '@mui/icons-material/RemoveRedEye';
import HideIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import type { MouseEvent } from 'react';

import type { AggregatableObject, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    updateAggregateGeometry,
    updateAnalyticalModelObject,
    updateInputObject,
    updateVolumeObject,
} from 'src/store/project/projectSlice';
import { selectCurrentModel, selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { getObjectVisibilityDescription } from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export const enum ObjectType {
    InputObject,
    AggregatableObject,
    Aggregate,
    Volume,
}

export const HideShowButtons = ({
    object,
    objectType,
}: {
    object: AggregatableObject | GtmProjectInput;
    objectType: ObjectType;
}) => {
    const { syncProject } = useProjectSynchronizer();
    const dispatch = useAppDispatch();
    const {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        highlightSceneObject,
        removeHighlightFromHighlightedObject,
    } = useSceneObjectDataManager();
    const applyTrace = useTrace('showhide');
    const currentModel = useAppSelector(selectCurrentModel);
    const sceneObject = useAppSelector(sceneObjectById(object.id));
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);

    const isObjectOnPlot = object.visible;

    const setVisible = (visible: boolean) => {
        switch (objectType) {
            case ObjectType.InputObject:
                dispatch(updateInputObject([object.id, { ...object, visible }]));
                break;
            case ObjectType.AggregatableObject:
                dispatch(
                    updateAnalyticalModelObject([
                        object.id,
                        { ...object, visible } as AggregatableObject,
                    ]),
                );
                break;
            case ObjectType.Aggregate:
                dispatch(updateAggregateGeometry([{ ...object, visible }]));
                break;
            case ObjectType.Volume:
                dispatch(updateVolumeObject([object.id, { ...object, visible }]));
                break;
            default:
                break;
        }
    };

    const handleHideItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        setVisible(false);
        syncProject({
            description: getObjectVisibilityDescription(
                object,
                false,
                objectType === ObjectType.InputObject ? undefined : (currentModel?.name as string),
            ),
        });
        removeGtmObject(object.id);
        if (selectedObject === object) {
            removeHighlightFromHighlightedObject();
        }
    };

    const handleShowItemOnClick = (event: MouseEvent) => {
        event.stopPropagation();
        setVisible(true);
        syncProject({
            description: getObjectVisibilityDescription(
                object,
                true,
                objectType === ObjectType.InputObject ? undefined : (currentModel?.name as string),
            ),
        });
        loadGtmObject(object.id, object.version, object.name);
        if (isObjectOnPlotByObjectId(object.id) && selectedObject === object && sceneObject) {
            highlightSceneObject(sceneObject);
        }
    };

    return (
        <ListItemIcon sx={{ minWidth: '36px' }}>
            {isObjectOnPlot ? (
                <IconButton
                    automation-id={applyTrace(`hide-button-${object.id}`)}
                    edge="end"
                    onClick={(event) => handleHideItemOnClick(event)}
                    size="small"
                >
                    <ShowIcon />
                </IconButton>
            ) : (
                <IconButton
                    automation-id={applyTrace(`show-button-${object.id}`)}
                    edge="end"
                    onClick={(event) => handleShowItemOnClick(event)}
                    size="small"
                >
                    <HideIcon />
                </IconButton>
            )}
        </ListItemIcon>
    );
};
