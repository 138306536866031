import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import type { SurfaceViewState, Color as XyzColor } from '@local/webviz/dist/types/xyz';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import { ColorPicker } from 'src/components/ColorPicker';
import { useObjectNonGeometryUpdate } from 'src/hooks/transformation/useObjectNonGeometryUpdate';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import {
    anAnalyticalModelIsSelected,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { htmlColorToXyzColor, xyzColorToHtmlColor } from 'src/utils/typeTransformations';

import {
    COLOUR_LABEL,
    OFF_LABEL,
    ON_LABEL,
    OPACITY_LABEL,
    WIREFRAME_LABEL,
} from './SettingsTab.constants';

export function SettingsTab() {
    const applyTrace = useTrace('appearance-tab');

    return (
        <Stack direction="column" p={2} gap={1} automation-id={applyTrace('root-stack')}>
            <WireframeRow />
            <Divider sx={({ spacing }) => ({ margin: spacing(0, -2) })} />
            <ColorRow />
            <Divider sx={({ spacing }) => ({ margin: spacing(0, -2) })} />
            <OpacityRow />
        </Stack>
    );
}

const objectWithUpdatedAppearance = (
    gooseObject: GetObjectResponse,
    updatedProperty: any,
): GetObjectResponse => ({
    ...gooseObject,
    object: {
        ...gooseObject.object,
        extensions: {
            ...gooseObject.object.extensions,
            appearance: {
                ...(gooseObject.object?.extensions?.appearance ?? {}),
                ...updatedProperty,
            },
        },
    },
});

// TODO: is there a better way to lay this out than these fixed widths?
const controlColumnWidth = 175;

function WireframeRow() {
    const { updateObjectWireframe } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const { objectNonGeometryUpdate, updateSceneGooseObject, isLoading } =
        useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();
    const analyticalModelSelected = useAppSelector(anAnalyticalModelIsSelected);

    function isChecked(): boolean {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        return surfaceView?.wireframe ?? false;
    }

    const handleChange = async (event: Event | React.SyntheticEvent, newValue: boolean) => {
        // Update the redux object
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            wireframe: newValue,
        });

        // Update the XYZ object
        updateObjectWireframe(currentObjectRef.id, newValue);

        // GTM should not persist any updates to input objects within a model
        if (analyticalModelSelected) {
            // Update goose and the store
            objectNonGeometryUpdate(newObject, { description: 'Update wireframe' });
        } else {
            updateSceneGooseObject(newObject);
        }
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" color="secondary">
                {WIREFRAME_LABEL}
            </Typography>
            <FormControlLabel
                sx={{ marginRight: 0 }}
                label={
                    <Typography variant="body2">{isChecked() ? ON_LABEL : OFF_LABEL}</Typography>
                }
                color="primary"
                control={
                    <Switch
                        size="small"
                        disabled={isLoading}
                        checked={isChecked()}
                        onChange={handleChange}
                    />
                }
            />
        </Stack>
    );
}

function ColorRow() {
    const { updateObjectColor } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const applyTrace = useTrace('color-row');
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const { objectNonGeometryUpdate, updateSceneGooseObject, isLoading } =
        useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();
    const analyticalModelSelected = useAppSelector(anAnalyticalModelIsSelected);

    const handleColorPickerOnSave = async (color: string) => {
        const xyzColor = htmlColorToXyzColor(color);
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            fillColor: xyzColor,
        });

        // Update the XYZ object
        updateObjectColor(currentObjectRef.id, xyzColor);

        // GTM should not persist any updates to input objects within a model
        if (analyticalModelSelected) {
            // Update goose and the store
            objectNonGeometryUpdate(newObject, { description: 'Update color' });
        } else {
            updateSceneGooseObject(newObject);
        }
    };

    const getColor = (): XyzColor => {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        return surfaceView?.color ?? [255, 255, 0];
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" color="secondary">
                {COLOUR_LABEL}
            </Typography>
            <Stack
                sx={{ position: 'relative' }}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <IconButton
                    component="label"
                    size="medium"
                    disabled={isLoading}
                    onClick={() => {
                        setOpenColorPicker(true);
                    }}
                >
                    <Icon
                        sx={{
                            borderRadius: '4px',
                            backgroundColor: xyzColorToHtmlColor(getColor()),
                            border: '1px solid #FAFCFF8F',
                        }}
                    />
                </IconButton>
                <ColorPicker
                    automation-id={applyTrace('color-picker')}
                    sx={{ position: 'absolute', right: '140px', top: '-32px' }}
                    open={openColorPicker}
                    onSave={handleColorPickerOnSave}
                    onClose={() => {
                        setOpenColorPicker(false);
                    }}
                    initialColor={xyzColorToHtmlColor(getColor()).toUpperCase()}
                />
                <Typography
                    variant="body2"
                    color="secondary"
                    automation-id={applyTrace('color-text')}
                >
                    {xyzColorToHtmlColor(getColor()).toUpperCase()}
                </Typography>
            </Stack>
        </Stack>
    );
}

function OpacityRow() {
    const { updateObjectOpacity } = useSceneObjectDataManager();
    const currentObjectRef = useAppSelector(selectCurrentModelSelectedObject)!;
    const geomObject = useAppSelector(sceneObjectById(currentObjectRef.id))!;
    const applyTrace = useTrace('opacity-row');
    const { objectNonGeometryUpdate, updateSceneGooseObject, isLoading } =
        useObjectNonGeometryUpdate();
    const { getEntityState } = useBaseXyz();
    const [opacity, setOpacity] = useState(100);
    const analyticalModelSelected = useAppSelector(anAnalyticalModelIsSelected);

    useEffect(() => {
        const surfaceView = getEntityState(`${currentObjectRef.id}`) as SurfaceViewState;
        setOpacity(Math.round((surfaceView?.opacity ?? 1.0) * 100));
    }, [currentObjectRef]);

    const handleChange = async (
        _event: Event | React.SyntheticEvent,
        newValue: number | number[],
    ) => {
        setOpacity(newValue as number);
    };

    const handleChangeCommitted = () => {
        const newOpacity = opacity / 100.0;
        const newObject = objectWithUpdatedAppearance(geomObject.gooseObject!, {
            opacity: newOpacity,
        });

        // Update the XYZ object
        updateObjectOpacity(currentObjectRef.id, newOpacity);

        // GTM should not persist any updates to input objects within a model
        if (analyticalModelSelected) {
            // Update goose and the store
            objectNonGeometryUpdate(newObject, { description: 'Update opacity' });
        } else {
            updateSceneGooseObject(newObject);
        }
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" color="secondary">
                {OPACITY_LABEL}
            </Typography>
            <Stack direction="row" width={controlColumnWidth} alignItems="center" gap={1.5}>
                <Typography variant="body2" color="secondary">
                    0
                </Typography>
                <Slider
                    size="small"
                    color="primary"
                    sx={({ spacing }) => ({
                        p: spacing(3, 0.75, 0.75),
                    })}
                    orientation="horizontal"
                    value={opacity}
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    step={5}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    automation-id={applyTrace('opacity-slider')}
                    disabled={isLoading}
                />
                <Typography variant="body2" color="secondary">
                    100
                </Typography>
            </Stack>
        </Stack>
    );
}
