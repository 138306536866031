import { useTrace } from '@local/web-design-system-2';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { METRES_UNIT_VALUE, PROJECT_DISTANCE_UNITS } from 'src/constants';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { updateProjectSettings } from 'src/store/project/projectSlice';
import { selectProjectSettings } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { SettingsPanelBase } from 'src/visualization/SettingsPanel/components/SettingsPanelBase';

import {
    getDistanceUnitsUpdateDescription,
    TITLE,
    UNITS_LABEL,
} from './ProjectSettingsPanel.constants';
import { useStyles } from './ProjectSettingsPanel.styles';

export function ProjectSettingsPanel() {
    const applyTrace = useTrace('project-settings-panel');
    return (
        <SettingsPanelBase automationId={applyTrace('root')} title={TITLE}>
            <SettingsSection />
        </SettingsPanelBase>
    );
}

function SettingsSection() {
    const { classes } = useStyles();
    return (
        <Stack direction="column" className={classes.settingsSection}>
            <DistanceUnitsInput />
        </Stack>
    );
}

function DistanceUnitsInput() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const projectSettings = useAppSelector(selectProjectSettings);
    const { syncProject } = useProjectSynchronizer();

    const handleOnChange = async (e: SelectChangeEvent<string>) => {
        const newDistanceUnits = e.target.value;
        dispatch(updateProjectSettings({ units: newDistanceUnits }));

        syncProject({
            description: getDistanceUnitsUpdateDescription(newDistanceUnits),
        });
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {UNITS_LABEL}
            </Typography>
            <Select
                variant="outlined"
                size="small"
                value={projectSettings?.units ?? METRES_UNIT_VALUE}
                sx={{ minWidth: '145px' }}
                onChange={handleOnChange}
            >
                {PROJECT_DISTANCE_UNITS.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                        <Typography variant="body2">{unit.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
}
