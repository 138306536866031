import { GtmModelType } from 'src/gtmProject';
import { UpdaterBase } from 'src/gtmProject/loader/updaters/UpdaterBase';

const version100 = { model: 1, revision: 0, addition: 0 };
const version110 = { model: 1, revision: 1, addition: 0 };

// eslint-disable-next-line @typescript-eslint/naming-convention
export class Updater_1_0_0_To_1_1_0 extends UpdaterBase {
    constructor() {
        super(version100, version110);
    }

    protected updateContent(projectJson: any): any {
        const updatedProjectJson = structuredClone(projectJson);
        updatedProjectJson.models = updatedProjectJson.models.map((model: any) =>
            this.updateModel(model),
        );
        return updatedProjectJson;
    }

    private updateModel(model: any): any {
        switch (model.type) {
            case GtmModelType.CrossSection:
                return this.updateCrossSectionModel(model);
            case GtmModelType.ParametrizedGeometry:
                return this.updateParameterizedGeometryModel(model);
            default:
                return model;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private updateCrossSectionModel(model: any): any {
        const updatedModel = { ...model };
        updatedModel.volumes = model.sourceVolumes;
        delete updatedModel.sourceVolumes;
        delete updatedModel.sourceAggregateGeometry;
        return updatedModel;
    }

    // eslint-disable-next-line class-methods-use-this
    private updateParameterizedGeometryModel(model: any): any {
        const updatedModel = { ...model };
        updatedModel.parameterizedVolumes = model.parametrizedObject;
        updatedModel.parametricGeometries = [];
        updatedModel.crossSections = [];
        delete updatedModel.parametrizedObject;
        return updatedModel;
    }
}
