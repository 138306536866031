import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import type { SxProps, Theme } from '@mui/material/styles';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { useAppSelector } from 'src/store/store';
import {
    selectShouldShowProjectPanel,
    selectIsCreatingBoundary,
    selectIsProjectMode,
    selectIsWorkspaceMode,
    selectIsModelMode,
} from 'src/store/ui/projectPanel';
import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';
import { ModelView } from 'src/visualization/ProjectPanel/components/ModelView/ModelView';
import { ProjectView } from 'src/visualization/ProjectPanel/components/ProjectView/ProjectView';
import { UrlListener } from 'src/visualization/ProjectPanel/components/UrlListener';
import { WorkspaceView } from 'src/visualization/ProjectPanel/components/WorkspaceView';

import { NoProjectsDialog } from './components/NoProjectsDialog';

interface ProjectPanelProps {
    sx?: SxProps<Theme>;
}

export function ProjectPanel({ sx }: ProjectPanelProps) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const shouldShowProjectPanel = useAppSelector(selectShouldShowProjectPanel);
    const shouldShowBoundaryDialog = useAppSelector(selectIsCreatingBoundary);

    return (
        <Box sx={sx}>
            <WDSThemeProvider themeMode={appTheme}>
                <UrlListener />
                {shouldShowProjectPanel && (
                    <Paper sx={{ width: DEFAULT_PANEL_WIDTH_PX }} elevation={4}>
                        {!shouldShowBoundaryDialog && <PanelView />}
                    </Paper>
                )}
                <NoProjectsDialog />
            </WDSThemeProvider>
        </Box>
    );
}

const PanelView = () => {
    const isProjectMode = useAppSelector(selectIsProjectMode);
    const isWorkspaceMode = useAppSelector(selectIsWorkspaceMode);
    const isModelMode = useAppSelector(selectIsModelMode);

    if (isProjectMode) {
        return <ProjectView />;
    }

    if (isWorkspaceMode) {
        return <WorkspaceView />;
    }

    if (isModelMode) {
        return <ModelView />;
    }

    return null;
};
