import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import type { CameraState } from '@local/webviz/dist/types/xyz';
import React, { useCallback } from 'react';

const DOWN_ROTATION = [0, 0, 0];

export const useXyzCameraRotationTween = () => {
    const { setStateFromSnapshot, getEntityState } = useBaseXyz();
    const initialCameraStateRef = React.useRef<CameraState>(
        getEntityState('camera') as CameraState,
    );

    const restoreCameraStateBeforeTween = useCallback(() => {
        // Azimuth and plunge are read-only. This avoids a bunch of warnings in
        // the console.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { azimuth, plunge, ...restCameraState } = initialCameraStateRef.current;
        setStateFromSnapshot({ camera: restCameraState }, { tween: { duration: 1000 } });
    }, [initialCameraStateRef]);

    const tweenCameraDownRotation = useCallback(() => {
        initialCameraStateRef.current = getEntityState('camera') as CameraState;
        setStateFromSnapshot(
            { camera: { rotation: DOWN_ROTATION } },
            { tween: { duration: 1000 } },
        );
    }, [initialCameraStateRef]);

    return { tweenCameraDownRotation, restoreCameraStateBeforeTween };
};
