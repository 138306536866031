import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button/Button';
import { useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { CrossSectionIcon } from 'src/assets/CrossSectionIcon';
import { ActionDialog } from 'src/components/ActionDialog/ActionDialog';
import { DEFAULT_MODEL_SETTINGS, DESIGN_GEOMETRY_SCHEMA } from 'src/constants';
import type { GtmCrossSectionModel } from 'src/gtmProject';
import { type CrossSection, GtmModelType } from 'src/gtmProject';
import { useCoordTransformation } from 'src/hooks/coordTransformation';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { selectWorkspaceName } from 'src/store/evo/selectors';
import {
    addModelToCurrentProject,
    updateModelAtIndexForCurrentProject,
} from 'src/store/project/projectSlice';
import {
    selectCurrentProjectModels,
    selectCurrentProjectName,
    selectCurrentProjectParameterizedVolumes,
    selectCurrentProjectVolumes,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { CLOSE_LABEL } from 'src/strings';
import type { ObjectIdWithVersion } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import {
    CONFIRM_LABEL,
    CROSS_SECTION_NAME_SUFFIX,
    getPublishModalMessage,
    getPublishNewerVersionTitle,
    GO_TO_MODEL,
    PUBLISH_NEWER_VERSION_MESSAGE,
    PUBLISH_TO_EVO_LABEL,
    PUBLISHING_TO_EVO_FAILED_MESSAGE,
    PUBLISHING_TO_EVO_MESSAGE,
    PUBLISHING_TO_EVO_SUCCESS_MESSAGE,
} from 'src/visualization/CrossSectionPanel/CrossSectionPanel.constants';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';
import { CANCEL_LABEL } from 'src/visualization/ProjectPanel/ProjectPanel.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

interface PublishCrossSectionToEvoModalInterface {
    shouldOpenConfirmDialog: boolean;
    closeConfirmDialog: () => void;
    crossSection: CrossSection;
}

export const PublishCrossSectionToEvoModal = ({
    shouldOpenConfirmDialog,
    closeConfirmDialog,
    crossSection,
}: Readonly<PublishCrossSectionToEvoModalInterface>) => {
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus, resetTransformationStatus } =
        useTransformationManager();
    const { globalToLocalCoord } = useCoordTransformation();
    const currentProjectName = useAppSelector(selectCurrentProjectName);
    const publishedModelUuidRef = useRef<string | null>(null);
    const currentParameterizedVolumes = useAppSelector(selectCurrentProjectParameterizedVolumes);
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const workspaceName = useAppSelector(selectWorkspaceName);
    const { navigateToModelUrl } = useGtmNavigator();
    const currentModels = useAppSelector(selectCurrentProjectModels);
    const { isCrossSectionPublished, matchingCrossSectionIndex } = useMemo(() => {
        const matchingIndex = currentModels.findIndex(
            (model) => model.type === GtmModelType.CrossSection && model.name === crossSection.name,
        );
        return {
            isCrossSectionPublished: matchingIndex > -1,
            matchingCrossSectionIndex: matchingIndex,
        };
    }, [currentModels, crossSection]);

    const addNewModelToProject = (createdObjects: ObjectIdWithVersion[]) => {
        const newModelUuid = uuidv4();
        publishedModelUuidRef.current = newModelUuid;
        const newCrossSectionModel: GtmCrossSectionModel = {
            type: GtmModelType.CrossSection,
            id: newModelUuid,
            name: crossSection.name,
            settings: DEFAULT_MODEL_SETTINGS,
            crossSection,
            volumes: currentVolumes,
            parameterizedCrossSection: decorateNewObject(
                createdObjects[0],
                `${crossSection.name}${CROSS_SECTION_NAME_SUFFIX}`,
                DESIGN_GEOMETRY_SCHEMA,
                false,
            ),
        };
        dispatch(addModelToCurrentProject(newCrossSectionModel));
    };

    const replaceModelInProject = (createdObjects: ObjectIdWithVersion[]) => {
        const matchingCrossSection = currentModels[
            matchingCrossSectionIndex
        ] as GtmCrossSectionModel;
        publishedModelUuidRef.current = matchingCrossSection.id;
        const newModelFields: Partial<GtmCrossSectionModel> = {
            crossSection,
            volumes: currentVolumes,
            parameterizedCrossSection: decorateNewObject(
                createdObjects[0],
                `${crossSection.name}${CROSS_SECTION_NAME_SUFFIX}`,
                DESIGN_GEOMETRY_SCHEMA,
                false,
            ),
        };
        dispatch(updateModelAtIndexForCurrentProject([newModelFields, matchingCrossSectionIndex]));
    };

    const handlePublishToEvo = async () => {
        if (!currentParameterizedVolumes) {
            return;
        }
        closeConfirmDialog();
        await executeTransformation(
            GtmMeshTransformationAction.CreateCrossSection,
            ShouldRenderUpdatedObjects.No,
            ShouldRunDetectorsOnUpdatedObjects.No,
            [currentParameterizedVolumes],
            {
                name: `${currentProjectName}/${crossSection.name}${CROSS_SECTION_NAME_SUFFIX}`,
                sectionOrigin: globalToLocalCoord(crossSection.origin),
                sectionDipAzimuthDegrees: getFlippedDipAzimuth(crossSection),
            },
            {
                createdObjectsHandler: (createdObjects) => {
                    if (isCrossSectionPublished) {
                        replaceModelInProject(createdObjects);
                    } else {
                        addNewModelToProject(createdObjects);
                    }
                },
            },
        );
    };

    const handleGoToModel = () => {
        resetTransformationStatus();
        if (publishedModelUuidRef.current) {
            navigateToModelUrl(publishedModelUuidRef.current);
        }
    };

    return (
        <>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
                actions={
                    transformationStatus === TransformationStatus.Complete ? (
                        <>
                            <Button size="small" onClick={resetTransformationStatus}>
                                {CLOSE_LABEL}
                            </Button>
                            <Button
                                size="small"
                                variant="outlined"
                                startIcon={<OpenInNewIcon />}
                                onClick={handleGoToModel}
                            >
                                {GO_TO_MODEL}
                            </Button>
                        </>
                    ) : (
                        <Button
                            size="small"
                            sx={{ ml: 'auto' }}
                            onClick={resetTransformationStatus}
                        >
                            {CLOSE_LABEL}
                        </Button>
                    )
                }
            />
            <ActionDialog
                open={shouldOpenConfirmDialog}
                title={
                    isCrossSectionPublished
                        ? getPublishNewerVersionTitle(crossSection.name)
                        : PUBLISH_TO_EVO_LABEL
                }
                message={
                    isCrossSectionPublished
                        ? PUBLISH_NEWER_VERSION_MESSAGE
                        : getPublishModalMessage(crossSection.name, workspaceName ?? 'workspace')
                }
                icon={
                    isCrossSectionPublished ? <WarningIcon color="warning" /> : <CrossSectionIcon />
                }
                actions={
                    <>
                        <Button size="small" onClick={closeConfirmDialog}>
                            {CANCEL_LABEL}
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handlePublishToEvo}
                            startIcon={<CheckIcon />}
                        >
                            {CONFIRM_LABEL}
                        </Button>
                    </>
                }
            />
        </>
    );
};

const titlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, PUBLISHING_TO_EVO_MESSAGE],
    [TransformationStatus.Uploading, PUBLISHING_TO_EVO_MESSAGE],
    [TransformationStatus.Complete, PUBLISHING_TO_EVO_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, PUBLISHING_TO_EVO_FAILED_MESSAGE],
]);
const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 60],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

const getFlippedDipAzimuth = (crossSection: CrossSection) => {
    const { shouldFlipSlice, dipAzimuthDegrees } = crossSection;
    if (!shouldFlipSlice) {
        return dipAzimuthDegrees;
    }
    return dipAzimuthDegrees + (dipAzimuthDegrees >= 180 ? -180 : 180);
};
