import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import type { ReactNode } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { DEFAULT_PANEL_WIDTH_PX } from 'src/styles';

interface SettingsPanelBaseProps {
    title: string;
    automationId?: string;
    children?: ReactNode;
}

const panelStyles = {
    width: DEFAULT_PANEL_WIDTH_PX,
    position: 'absolute',
    right: '16px',
    top: '16px',
    zIndex: 2,
};

export const SettingsPanelBase = ({ title, children, automationId }: SettingsPanelBaseProps) => {
    const { theme: appTheme } = useContext(WDS2ThemeContext);

    return (
        <Box sx={panelStyles} automation-id={automationId}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <DialogTitle sx={(theme) => ({ padding: theme.spacing(2) })}>
                        {title}
                    </DialogTitle>
                    <Divider />
                    {children}
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
};
