export const REMESH_LABEL = 'Remesh Degenerate Triangles';
export const APPLY_LABEL = 'Apply';

export const PATCH_ANGLE_TOLERANCE = 'Angle threshold';
export const MAX_CHORDAL_ERROR = 'Maximum deviation';
export const RADIUS = 'Radius';

export const STATUS_TRANSFORMING = 'Remeshing...';
export const STATUS_COMPLETE = 'Remeshing complete';
export const STATUS_FAILED = 'Failed to remesh';

export const HELP_WHAT_IS_LOCAL_REMESHING = 'What is Local Remeshing?';
export const HELP_DEGEN_REMESHING_INFO = `This tool remeshes the geometry around and including degenerate triangles,
 fitting a new triangulation that approximates the original while preserving the mesh beyond the specified radius.`;

export const RADIUS_INFO =
    'Local remeshing remeshing will be performed on triangles that have points within the specified radius of the degenerate triangle centroids.';
