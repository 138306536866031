/* eslint-disable no-param-reassign */

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { LocalRemeshSettingsState } from './localRemeshSettings.types';
import { initialLocalRemeshSettingsState } from './selectors';

export const localRemeshSettingsSlice = createSlice({
    name: 'localRemeshSettings',
    initialState: initialLocalRemeshSettingsState,
    reducers: {
        setAllLocalRemeshSettings(
            state: LocalRemeshSettingsState,
            { payload: newRemeshSettings }: PayloadAction<LocalRemeshSettingsState>,
        ) {
            return newRemeshSettings;
        },
        setPatchAngleTolerance(
            state: LocalRemeshSettingsState,
            { payload: newPatchAngleTolerance }: PayloadAction<string>,
        ) {
            state.patchAngleTolerance = newPatchAngleTolerance;
        },
        setPatchAngleToleranceValid(
            state: LocalRemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.patchAngleToleranceValid = isValidSetting;
        },
        setMaxChordalError(
            state: LocalRemeshSettingsState,
            { payload: newMaxChordalError }: PayloadAction<string>,
        ) {
            state.maxChordalError = newMaxChordalError;
        },
        setMaxChordalErrorValid(
            state: LocalRemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.maxChordalErrorValid = isValidSetting;
        },
        setRadius(state: LocalRemeshSettingsState, { payload: newRadius }: PayloadAction<string>) {
            state.radius = newRadius;
        },
        setRadiusValid(
            state: LocalRemeshSettingsState,
            { payload: isValidSetting }: PayloadAction<boolean>,
        ) {
            state.radiusValid = isValidSetting;
        },
    },
});

export const {
    setAllLocalRemeshSettings,
    setPatchAngleTolerance,
    setPatchAngleToleranceValid,
    setMaxChordalError,
    setMaxChordalErrorValid,
    setRadius,
    setRadiusValid,
} = localRemeshSettingsSlice.actions;
