import { Updater_1_0_0_To_1_1_0 } from 'src/gtmProject/loader/updaters/Updater_1_0_0_To_1_1_0';
import { UpdaterChainBase } from 'src/gtmProject/loader/updaters/UpdaterChainBase';

/**
 * Concrete class to apply a chain of updates to project JSON files.
 * This class must be updated when the project schema changes, i.e. a new concrete updater class to
 * handle the change must be created and added to the chain in the `initialize` method.
 */
export class UpdaterChain extends UpdaterChainBase {
    public initialize(): void {
        this.updatersList = [new Updater_1_0_0_To_1_1_0()];
    }
}
