import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const DisaggregateIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <g fill="none">
            <path
                d="M12 2L3 7L12 12L21 7L12 2Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <path
                d="M2 13.5V17L12 22L22 17V13L19.7778 12L12 15.5L4.77778 12L2 13.5Z"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
