export interface SettingsPanelState {
    crossSectionPanelMode: CrossSectionPanelMode;
}

export const enum CrossSectionPanelMode {
    None,
    DrawLine,
    EditProperties,
    ViewProperties,
}
