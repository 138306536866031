import { useMemo } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { MESH_SCHEMA } from 'src/constants';
import {
    useTransformationManager,
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { clearVolumes, setVolumes } from 'src/store/project/projectSlice';
import {
    makeSelectIsObjectOutdatedOrMissing,
    selectCurrentProjectVolumes,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector, useGetLatestState } from 'src/store/store';
import type { ObjectIdWithVersion } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';

export function useVolumesManager() {
    const dispatch = useAppDispatch();
    const { executeTransformation, transformationStatus: volumesComputationStatus } =
        useTransformationManager();
    const { removeGtmObject } = useSceneObjectDataManager();
    const currentVolumes = useAppSelector(selectCurrentProjectVolumes);
    const memoizedSelectIsObjectOutdatedOrMissing = useMemo(
        makeSelectIsObjectOutdatedOrMissing,
        [],
    );
    const getLatestState = useGetLatestState();

    async function computeVolumes(aggregateGeometry: ObjectIdWithVersion) {
        await executeTransformation(
            GtmMeshTransformationAction.SeparateVolumes,
            ShouldRenderUpdatedObjects.No,
            ShouldRunDetectorsOnUpdatedObjects.No,
            [aggregateGeometry],
            {},
            {
                createdObjectsHandler: (createdObjects) => {
                    currentVolumes.forEach((obj) => removeGtmObject(obj.id));
                    const newVolumes = createdObjects.map((obj, index) =>
                        decorateNewObject(obj, `Volume ${index + 1}`, MESH_SCHEMA, true),
                    );
                    dispatch(setVolumes(newVolumes));
                },
                cancellationPredicate: () =>
                    getLatestState((state) =>
                        memoizedSelectIsObjectOutdatedOrMissing(state, aggregateGeometry),
                    ),
            },
        );
    }

    function resetVolumes() {
        dispatch(clearVolumes());
    }

    return {
        computeVolumes,
        resetVolumes,
        volumesComputationStatus,
    };
}
