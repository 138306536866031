import { createSelector } from '@reduxjs/toolkit';

import { defaultAnalyticalModelSettings } from 'src/apiClients/gtmCompute/gtmComputeApi';
import type { GtmAnalyticalModelSettings } from 'src/gtmProject';
import type { RootState } from 'src/store/store';
import { stringAsFiniteNumber } from 'src/utils/math';

import type { LocalRemeshSettingsState } from './localRemeshSettings.types';

export function localRemeshSettingsFromAnalyticalModelSettings(
    analyticalModelSettings: GtmAnalyticalModelSettings,
): LocalRemeshSettingsState {
    return {
        patchAngleTolerance:
            analyticalModelSettings.localRemeshSettings.patchAngleTolerance.toString(),
        patchAngleToleranceValid: true,
        maxChordalError: analyticalModelSettings.localRemeshSettings.maxChordalError.toString(),
        maxChordalErrorValid: true,
        radius: analyticalModelSettings.localRemeshSettings.radius.toString(),
        radiusValid: true,
    };
}

export function analyticalModelSettingsFromLocalRemeshSettings(
    existingAnalyticalModelSettings: GtmAnalyticalModelSettings,
    remeshSettings: LocalRemeshSettingsState,
): GtmAnalyticalModelSettings {
    return {
        ...existingAnalyticalModelSettings,
        localRemeshSettings: {
            patchAngleTolerance: stringAsFiniteNumber(remeshSettings.patchAngleTolerance),
            maxChordalError: stringAsFiniteNumber(remeshSettings.maxChordalError),
            radius: stringAsFiniteNumber(remeshSettings.radius),
        },
    };
}

export const initialLocalRemeshSettingsState = localRemeshSettingsFromAnalyticalModelSettings(
    defaultAnalyticalModelSettings,
);

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;

const localRemeshSettingsState = (state: RootState): LocalRemeshSettingsState =>
    state.localRemeshSettings;

export const selectAllLocalRemeshSettings = localRemeshSettingsState;

export const selectPatchAngleTolerance: SelectorTypeString = createSelector(
    localRemeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleTolerance,
);

export const selectPatchAngleToleranceValid: SelectorTypeBoolean = createSelector(
    localRemeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.patchAngleToleranceValid,
);

export const selectRadius: SelectorTypeString = createSelector(
    localRemeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.radius,
);

export const selectRadiusValid: SelectorTypeBoolean = createSelector(
    localRemeshSettingsState,
    (remeshSettingsStateRoot) => remeshSettingsStateRoot.radiusValid,
);
