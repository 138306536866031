import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import type { SliceState } from '@local/webviz/dist/types/xyz';
import { distance } from '@local/webviz/dist/utilities';
import { SliceMode } from '@local/webviz/dist/xyz';
import { useEffect, useState } from 'react';

import type { GtmCrossSectionModel } from 'src/gtmProject';
import {
    CrossSectionReadOnlyPanel,
    SLICE_TOOL_DIP_ANGLE_DEGREES,
} from 'src/visualization/CrossSectionPanel/CrossSectionPanel';

interface CrossSectionModelViewProps {
    crossSectionModel: GtmCrossSectionModel;
}

export const CrossSectionModelView = ({
    crossSectionModel,
}: Readonly<CrossSectionModelViewProps>) => {
    const { getSliceTool, setXyzStateDirectly, addListener, clearSlice } = useBaseXyz();
    const sliceTool = getSliceTool();
    const [sliderPosition, setSliderPosition] = useState(0);

    useEffect(() => {
        const removeListener = addListener('slice', 'path', handlePathChange);
        return () => {
            removeListener();
            clearSlice();
        };
    }, []);

    useEffect(() => {
        const { crossSection } = crossSectionModel;
        sliceTool.setDip(SLICE_TOOL_DIP_ANGLE_DEGREES);
        sliceTool.setDipAzimuth(crossSection.dipAzimuthDegrees);
        sliceTool.setMode(crossSection.shouldFlipSlice ? SliceMode.Back : SliceMode.Front);
        const { x, y, z } = crossSection.origin;
        const sliceSnapshot = { slice: { active: true, location: [x, y, z] } };
        setXyzStateDirectly(sliceSnapshot);
    }, [crossSectionModel]);

    const handlePathChange = ({ centre, line }: SliceState['path']) => {
        const [point1, point2] = line;
        const lineDistance = distance(point1, point2);
        if (lineDistance === 0) {
            return;
        }
        const centreDistance = distance(point1, centre);
        setSliderPosition(centreDistance / lineDistance);
    };

    return (
        <CrossSectionReadOnlyPanel
            sliderPosition={sliderPosition}
            crossSection={crossSectionModel.crossSection}
            hideName
            hideActions
        />
    );
};
