/* eslint-disable import/no-cycle */

import { gooseClient } from '@api/goose/dist/enhancedGooseClient';
import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import { workspaceClient } from '@local/workspaces/dist/apiClients/workspaceClient';
import type { Reducer, ThunkAction, UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { fileClient } from 'src/apiClients/file/fileClient';
import { gtmComputeApi } from 'src/apiClients/gtmCompute/gtmComputeApi';

import { evoSlice } from './evo/evoSlice';
import { issuesSlice } from './issues/issuesSlice';
import { projectSlice } from './project/projectSlice';
import { detectionSettingsSlice } from './ui/detectionSettings';
import { localRemeshSettingsSlice } from './ui/localRemeshSettings/localRemeshSettingsSlice';
import { projectPanelSlice } from './ui/projectPanel';
import { remeshSettingsSlice } from './ui/remeshSettings/remeshSettingsSlice';
import { settingsPanelSlice } from './ui/settingsPanel';
import { visualizationSlice } from './visualization/visualizationSlice';

const combinedReducer = combineReducers({
    [fileClient.reducerPath]: fileClient.reducer,
    [gtmComputeApi.reducerPath]: gtmComputeApi.reducer,
    [gooseClient.reducerPath]: gooseClient.reducer,
    [visualizationSlice.name]: visualizationSlice.reducer,
    [workspaceClient.reducerPath]: workspaceClient.reducer,
    [evoSlice.name]: evoSlice.reducer,
    [projectSlice.name]: projectSlice.reducer,
    [issuesSlice.name]: issuesSlice.reducer,
    [projectPanelSlice.name]: projectPanelSlice.reducer,
    [detectionSettingsSlice.name]: detectionSettingsSlice.reducer,
    [remeshSettingsSlice.name]: remeshSettingsSlice.reducer,
    [localRemeshSettingsSlice.name]: localRemeshSettingsSlice.reducer,
    [settingsPanelSlice.name]: settingsPanelSlice.reducer,
    ...loginReducers,
});

const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

export const createStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: {
                    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
                },
            }).concat(
                fileClient.middleware,
                gtmComputeApi.middleware,
                gooseClient.middleware,
                workspaceClient.middleware,
                ...loginMiddleware,
            ),
    });

export const store = createStore();

// Infer the `RootState` from combinedReducer
export type RootState = ReturnType<typeof combinedReducer>;
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    UnknownAction
>;

/**
 * This is a thunk action that can be used to get the most up-to-date value from the store using a selector
 *
 * Use this instead of using `store.getState()`
 *
 * Example Use:
 *
 * `const currentProject = dispatch(selectLatestState(selectCurrentProject))`
 *
 * @param selector Any store selector
 */
export const selectLatestStateThunk =
    <T>(selector: (state: RootState) => T): AppThunk<T> =>
    (_: unknown, getState: () => RootState) =>
        selector(getState());

/**
 * This hook is convenience wrapper around the `selectLatestStateThunk`
 *
 * Use this with selectors using parameters
 *
 * @return A function which accepts a selector returns the latest state value referenced by the specified selector
 *
 */
export const useGetLatestState = () => {
    const dispatch = useAppDispatch();
    return useCallback(
        <T>(selector: (state: RootState) => T) => dispatch(selectLatestStateThunk(selector)),
        [],
    );
};

/**
 * This hook is convenience wrapper around the `selectLatestStateThunk`
 *
 * Use this with regular selectors that do not require parameters
 *
 * @param selector Any store selector
 * @return A function which returns the latest state value referenced by the input selector to the hook
 *
 */
export const useGetLatestSelectorState = <T>(selector: (state: RootState) => T) => {
    const getLatestState = useGetLatestState();
    return useCallback(() => getLatestState(selector), []);
};
