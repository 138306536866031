import { useState } from 'react';

import {
    defaultAnalyticalModelSettings,
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { DEFAULT_TOLERANCE } from 'src/apiClients/gtmCompute/gtmComputeApi.constants';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AGGREGATE_GEOMETRY_NAME, DEFAULT_MODEL_SETTINGS, MESH_SCHEMA } from 'src/constants';
import type {
    AggregatableObject,
    GtmAnalyticalModel,
    GtmBounds,
} from 'src/gtmProject/Project.types';
import { GtmModelType } from 'src/gtmProject/Project.types';
import { useLazyInitAggregate } from 'src/hooks/aggregation/useLazyInitAggregate';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { addModelToCurrentProject } from 'src/store/project/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import type { GeoscienceObject, ObjectId } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { assert } from 'src/utils/gtmAssert';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';

export enum BoundaryCreationState {
    STARTED,
    ANALYTICAL_BOUNDARY_CREATED,
    AGGREGATE_GEOMETRY_INITIALIZED,
    PROJECT_UPLOADED,
    SUCCESS,
    ERROR,
}

const getNewAnalyticalModelDescription = (modelName: string) =>
    `Add new analytical model "${modelName}"`;

// return { createNewAnalyticalModelInProject, boundaryCreationStatus, resetBoundaryCreationStatus }
// createNewAnalyticalModelInProject - function encapsulating common logic for boundary/analytical model creation.
// boundaryCreationStatus - stateful variable to track the status of the creation.
// resetBoundaryCreationStatus - function to reset the boundary creation status. While the manager will reset it upon a new call,
//                               exposing this helper is useful for UI components that key off of the status.
//
// The purpose of this hook is to encapsulate common logic for creating a new analytical model in a project.
// The steps are as follows:
//    1. Clip all input objects to the desired boundary extents.
//        a. Right now we're parallelizing requests for each input, but it may be more efficient to do them all in one request, or batch them.
//           Note that the inputObjects argument is taken as GeoscienceObject. This, combined with this parallelization, allows
//           us to keep track of the inputs to the outputs, thus allowing us to keep track of the name (which we would otherwise lose).
//    2. Initialize the aggregate geometry.
//        a. Create an "empty shell" defined by the boundary extents.
//    3. Synchronize the project file.
export function useBoundaryCreationManager() {
    const [boundaryCreationStatus, setBoundaryCreationStatus] = useState<
        BoundaryCreationState | undefined
    >(undefined);

    const { createAnalyticalBoundary } = useBoundaryCreator(setBoundaryCreationStatus);
    const { initializeAggregateGeometry } = useAggregateInitializer(setBoundaryCreationStatus);
    const { updateAndUploadProject } = useProjectSync(setBoundaryCreationStatus);

    function resetBoundaryCreationStatus() {
        setBoundaryCreationStatus(undefined);
    }

    async function createNewAnalyticalModelInProject(
        inputObjects: GeoscienceObject[],
        id: ObjectId,
        name: string,
        bounds: GtmBounds,
    ) {
        setBoundaryCreationStatus(BoundaryCreationState.STARTED);
        let aggregateGeometry;

        try {
            const objects = await createAnalyticalBoundary(inputObjects, name, bounds);
            aggregateGeometry = await initializeAggregateGeometry(bounds, id);
            await updateAndUploadProject({
                type: GtmModelType.Analytical,
                id,
                name,
                bounds,
                inputObjects,
                objects,
                aggregateGeometry,
                settings: DEFAULT_MODEL_SETTINGS,
                analyticalModelSettings: defaultAnalyticalModelSettings,
                volumes: [],
                crossSections: [],
                parametricGeometries: [],
            });
            setBoundaryCreationStatus(BoundaryCreationState.SUCCESS);
        } catch {
            setBoundaryCreationStatus(BoundaryCreationState.ERROR);
        }
    }

    return {
        createNewAnalyticalModelInProject,
        boundaryCreationStatus,
        resetBoundaryCreationStatus,
    };
}

export function useBoundaryCreator(
    setBoundaryCreationStatus: (status: BoundaryCreationState) => void,
) {
    const gooseContext = useGooseContext();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const sceneObjects = useAppSelector(sceneObjectMap);

    return {
        createAnalyticalBoundary: async (
            inputObjects: GeoscienceObject[],
            name: string,
            bounds: GtmBounds,
        ) =>
            Promise.all(
                inputObjects.map((input) =>
                    GtmMeshTransformationTrigger(
                        formGtmMeshTransformationBody(
                            gooseContext!,
                            GtmMeshTransformationAction.CreateAnalyticalBoundary,
                            [input],
                            {
                                ...bounds,
                                projectName: name,
                                tolerance: DEFAULT_TOLERANCE,
                                extensions: sceneObjects[input.id]?.gooseObject?.object?.extensions,
                            },
                        ),
                    )
                        .unwrap()
                        .then(({ created }): AggregatableObject[] => {
                            // Add newly created objects. I
                            if (created.length === 0) {
                                return [];
                            }
                            assert(
                                created.length === 1,
                                'Did not expect multiple objects created for CreateAnalyticalBoundary transformation.',
                            );
                            return [
                                {
                                    ...input,
                                    ...created[0],
                                    isAggregated: false,
                                },
                            ];
                        }),
                ),
            ).then((objects) => {
                setBoundaryCreationStatus(BoundaryCreationState.ANALYTICAL_BOUNDARY_CREATED);
                return objects.flat();
            }),
    };
}

function useAggregateInitializer(
    setBoundaryCreationStatus: (status: BoundaryCreationState) => void,
) {
    const [InitAggregateTrigger] = useLazyInitAggregate();

    return {
        initializeAggregateGeometry: async (bounds: GtmBounds, id: ObjectId) =>
            InitAggregateTrigger({ ...bounds, boundaryId: id })
                .then(({ initResult }) =>
                    decorateNewObject(
                        initResult!.created[0],
                        AGGREGATE_GEOMETRY_NAME,
                        MESH_SCHEMA,
                        true,
                    ),
                )
                .then((initializedAggregate) => {
                    setBoundaryCreationStatus(BoundaryCreationState.AGGREGATE_GEOMETRY_INITIALIZED);
                    return initializedAggregate;
                }),
    };
}

function useProjectSync(setBoundaryCreationStatus: (status: BoundaryCreationState) => void) {
    const dispatch = useAppDispatch();
    const { syncProject } = useProjectSynchronizer();
    const { navigateToModelUrl } = useGtmNavigator();

    return {
        updateAndUploadProject: async (analyticalModel: GtmAnalyticalModel) => {
            dispatch(addModelToCurrentProject(analyticalModel));
            navigateToModelUrl(analyticalModel.id);
            return syncProject({
                description: getNewAnalyticalModelDescription(analyticalModel.name as string),
            }).then(() => {
                setBoundaryCreationStatus(BoundaryCreationState.PROJECT_UPLOADED);
            });
        },
    };
}
