import { BoundaryPointer } from 'src/assets/BoundaryPointer';
import { DrawingModeToolbar } from 'src/components/DrawingModeToolbar/DrawingModeToolbar';

import { DRAW_RECTANGLE_PROMPT } from './BoundaryCreationToolbar.constants';

interface BoundaryDrawRectangleToolbarProps {
    onCancel: () => void;
}

export const BoundaryDrawRectangleToolbar = ({
    onCancel,
}: Readonly<BoundaryDrawRectangleToolbarProps>) => (
    <DrawingModeToolbar
        onCancel={onCancel}
        icon={<BoundaryPointer width="24" height="24" viewBox="0 0 24 24" opacity={0.56} />}
        prompt={DRAW_RECTANGLE_PROMPT}
    />
);
