import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import IconButton from '@mui/material/IconButton/IconButton';

import { DEFAULT_TOLERANCE } from 'src/apiClients/gtmCompute/gtmComputeApi.constants';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AggregateIcon } from 'src/assets/AggregateIcon';
import type { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import {
    TransformationStatus,
    useTransformationManager,
    shouldRenderObject,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { toRequestObject } from 'src/utils/typeTransformations';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import {
    AGGREATION_SUCCESS_MESSAGE,
    AGGREGATING_GEOM_MESSAGE,
    AGGREGATION_FAILURE_MESSAGE,
} from './AggregateControl.constants';

export function AggregateControl({
    inputMesh,
    handleAggregateObject,
}: Readonly<{
    inputMesh: GtmEvoOutputObject;
    handleAggregateObject: () => void;
}>) {
    const applyTrace = useTrace('aggregate-control');

    const { executeTransformation, transformationStatus, resetTransformationStatus } =
        useTransformationManager();
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();

    const performAggregation = async () => {
        await executeTransformation(
            GtmMeshTransformationAction.AddToAggregateGeom,
            shouldRenderObject(currentAggregateGeometry!),
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [inputMesh],
            {
                aggregateGeomId: toRequestObject(currentAggregateGeometry!),
                tolerance: DEFAULT_TOLERANCE,
                noSelfIntersectionsInParts: true,
            },
            {
                handleAdditionalSideEffects: () => {
                    resetVolumes();
                    resetParameterizedVolumes();
                    handleAggregateObject();
                },
            },
        );
    };

    const titlesByStatus = new Map<TransformationStatus, string>([
        [TransformationStatus.Transforming, AGGREGATING_GEOM_MESSAGE],
        [TransformationStatus.Uploading, AGGREGATING_GEOM_MESSAGE],
        [TransformationStatus.Complete, AGGREATION_SUCCESS_MESSAGE],
        [TransformationStatus.Failed, AGGREGATION_FAILURE_MESSAGE],
    ]);
    const percentagesByStatus = new Map<TransformationStatus, number>([
        [TransformationStatus.Transforming, 0],
        [TransformationStatus.Uploading, 70],
        [TransformationStatus.Complete, 100],
        [TransformationStatus.Failed, 100],
    ]);
    return (
        <>
            <IconButton
                edge="end"
                size="small"
                onClick={performAggregation}
                automation-id={applyTrace(`perform-aggregation-${inputMesh.id}`)}
            >
                <AggregateIcon />
            </IconButton>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
                resetStatus={resetTransformationStatus}
            />
        </>
    );
}
