import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export const TriangulatedIcon = (props: SvgIconProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <g fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0758 6.21645L16.5083 10.1408L13.3032 2.30628L20.0758 6.21645ZM21.0407 8.12827L17.534 11.9857L21.0407 15.8431V8.12827ZM20.0758 17.7549L16.4889 13.8093L13.0627 21.804L20.0758 17.7549ZM10.9019 21.7685L14.33 13.7696L3.95744 17.7591L10.9019 21.7685ZM2.98535 15.9901V8.16421L13.351 12.0033L2.98535 15.9901ZM3.76838 6.32146L14.395 10.2572L11.0622 2.11037L3.76838 6.32146Z"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
