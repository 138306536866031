import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';

export const ISSUES_DETECTION_SETTINGS_MESSAGE = 'Adjust how issues are detected in ';
export const DETECTION_SETTINGS = 'Detection Settings';
export const CREATE_ANALYTICAL_MODEL_MESSAGE = 'Please create an analytical model to detect issues';
export const NO_ISSUES_MESSAGE = 'No issues detected';

// TODO: GEOM-821: update explanations
export const issueKeyToHelperInfoMap = new Map<string, [title: string, explanation: string]>([
    [
        GtmMeshDetectorAction.DetectDegenerateTris,
        ['What are degenerate triangles?', 'Triangles with near zero area'],
    ],
    [
        GtmMeshDetectorAction.DetectDuplicatePoints,
        ['What are duplicate points?', 'A point defined identically to another point'],
    ],
    [
        GtmMeshDetectorAction.DetectDuplicateTris,
        ['What are duplicate triangles?', 'A triangle defined identically to another triangle'],
    ],
    [
        GtmMeshDetectorAction.DetectHoles,
        ['What are holes?', 'Holes are interior groups of missing triangles'],
    ],
    [
        GtmMeshDetectorAction.DetectNonPartitioningSurfaces,
        [
            'What are non-partitioning surfaces?',
            'An aggregated surface which does not contribute to volume partitioning',
        ],
    ],
    [
        GtmMeshDetectorAction.DetectSelfIntersections,
        [
            'What are self-intersections?',
            'Occurs when triangles of the same object intersect each other',
        ],
    ],
]);
