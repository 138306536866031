export const DEFAULT_TOLERANCE = 1e-6;
// Default needle threshold loosely linked to cap definition: for an isoceles cap triangle at the
// threshold we have that length-of-base / cap-height = 89.5, hence rounded up we set the ratio to 100.
export const DEFAULT_NEEDLETHRESHOLDRATIO = 100;
export const DEFAULT_CAPMINANGLEDEGREES = 177.5;
export const DEFAULT_HOLESIZERATIOTOLERANCE = 0.5;
export const DEFAULT_NEEDLECOLLAPSELENGTH = 0;

export const DEFAULT_PATCHANGLETOLERANCE = 10;
export const DEFAULT_MAXCHORDALERROR = -0.005;
export const DEFAULT_SHAPEQUALITYWEIGHT = 0.6;
export const DEFAULT_TARGETH = 0;
export const DEFAULT_ISCLOSED = false;
export const DEFAULT_LINEPROXIMITYDETECTION = false;
export const DEFAULT_STRAINTOLERANCE = 0.3; // CM2 default
export const DEFAULT_INITIALCLEANUP = true;
export const DEFAULT_OPTIMIZE = true;
export const DEFAULT_REGURALIZE_MODE = false;

export const DEFAULT_LOCAL_REMESH_RADIUS = 50;
