import { UpdaterChain } from 'src/gtmProject/loader/updaters/UpdaterChain';
import type { UpdaterChainBase } from 'src/gtmProject/loader/updaters/UpdaterChainBase';
import { isSameVersion, isValid } from 'src/gtmProject/loader/utils';
import { CURRENT_PROJECT_SCHEMA_VERSION } from 'src/gtmProject/Project.constants';
import type { SchemaVersion, GtmProject } from 'src/gtmProject/Project.types';

export type SchemaValidator = (projectJson: any, version: SchemaVersion) => Promise<boolean>;

/**
 * Class to load a project JSON file, validate it against a schema version, and update it to the
 * latest schema version if necessary.
 */
export class ProjectLoader {
    private updaterChain: UpdaterChainBase;

    private validator: SchemaValidator;

    private latestVersion: SchemaVersion;

    /**
     * Constructor with optional parameters to inject dependencies for testing.
     * Client code should use the default constructor without optional arguments.
     */
    constructor(
        updaterChain?: UpdaterChainBase,
        validator?: SchemaValidator,
        latestVersion?: SchemaVersion,
    ) {
        this.updaterChain = updaterChain ?? new UpdaterChain();
        this.validator = validator ?? isValid;
        this.latestVersion = latestVersion ?? CURRENT_PROJECT_SCHEMA_VERSION;
    }

    private async validate(projectJson: any, version: SchemaVersion): Promise<void> {
        const validationResult = await this.validator(projectJson, version);
        if (!validationResult) {
            throw new Error('Invalid project file.');
        }
    }

    public async load(projectJson: any): Promise<GtmProject> {
        if (!projectJson.schemaVersion) {
            throw new Error('Project file does not have a version.');
        }

        await this.validate(projectJson, projectJson.schemaVersion);
        if (!isSameVersion(projectJson.schemaVersion, this.latestVersion)) {
            const updatedProject = this.updaterChain.update(projectJson);
            // Safety measure to catch any potential bugs in the updaters.
            await this.validate(updatedProject, this.latestVersion);
            return updatedProject;
        }

        return projectJson as GtmProject;
    }
}
