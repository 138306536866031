import type { UpdaterBase } from 'src/gtmProject/loader/updaters/UpdaterBase';
import { isSameVersion } from 'src/gtmProject/loader/utils';

/**
 * Abstract base class to apply a chain of updates to project JSON files.
 */
export abstract class UpdaterChainBase {
    protected updatersList: UpdaterBase[];

    constructor() {
        this.updatersList = [];
    }

    public get updaters(): UpdaterBase[] {
        return this.updatersList;
    }

    public abstract initialize(): void;

    public update(projectJson: any): any {
        // Lazy initialization: initialize only when the project file needs to be updated.
        this.initialize();
        const updaterIndex = this.updatersList.findIndex((updater) =>
            isSameVersion(updater.sourceVersion, projectJson.schemaVersion),
        );

        if (updaterIndex === -1) {
            throw new Error('No updater found for the given schema version.');
        }

        let updatedProjectJson = structuredClone(projectJson);
        for (let i = updaterIndex; i < this.updatersList.length; i += 1) {
            updatedProjectJson = this.updatersList[i].update(updatedProjectJson);
        }
        return updatedProjectJson;
    }
}
