export const getPublishingToWorkspaceTitle = (workspaceName: string) =>
    `Publishing to ${workspaceName}`;
export const getPublishingModelTitle = (modelName: string) => `Publishing ${modelName}`;
export const getModelAlreadyExistsTitle = (modelName: string) => `"${modelName}" already exists`;
export const getModelPublishSuccessTitle = (modelName: string) => `"${modelName}" published`;
export const getModelPublishFailedTitle = (modelName: string) => `"${modelName}" failed to publish`;
export const ENTER_NAME_PLACEHOLDER = 'Enter model name';
export const PUBLISH_LABEL = 'Publish';
export const getAddParametricModelDescription = (modelName: string, projectName: string) =>
    `Add parametric model"${modelName}" to project "${projectName}"`;
export const getModifyParametricModelDescription = (modelName: string, projectName: string) =>
    `Modify parametric model"${modelName}" in project "${projectName}"`;
export const MODEL_ALREADY_EXISTS_MESSAGE = 'Would you like to replace it with a newer version?';
export const getPublishingMessage = (workspaceName: string) => `to ${workspaceName}`;
export const CONFIRM_PUBLISH_LABEL = 'Yes, publish';
export const BACK_LABEL = 'Back';
