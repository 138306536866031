import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DisaggregateIcon } from 'src/assets/DisaggregateIcon';
import { AGGREGATE_GEOMETRY_NAME, MESH_SCHEMA } from 'src/constants';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import {
    TransformationStatus,
    useTransformationManager,
    shouldRenderObject,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { disaggregateAllObjects, setAggregate } from 'src/store/project/projectSlice';
import { selectCurrentBounds, selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import type { ObjectId } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import {
    RESET_AGGREGATE_FAILURE_MESSAGE,
    RESET_AGGREGATE_GEOM_MESSAGE,
    RESET_AGGREGATE_SUCCESS_MESSAGE,
} from './ResetAggregateControl.constants';

export function ResetAggregateControl() {
    const applyTrace = useTrace('reset-aggregate-control');
    const currentBounds = useAppSelector(selectCurrentBounds);
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const dispatch = useAppDispatch();

    const { clearVisualizationAndIssuesForObject } = useConglomerateActionManager();
    const { executeTransformation, transformationStatus, resetTransformationStatus } =
        useTransformationManager();
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();

    async function performReset(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        await executeTransformation(
            GtmMeshTransformationAction.InitAggregateGeom,
            shouldRenderObject(currentAggregateGeometry!),
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [],
            {
                ...currentBounds!,
                boundaryId: uuidv4() as ObjectId,
            },
            {
                createdObjectsHandler: (createdObjects) =>
                    dispatch(
                        setAggregate(
                            decorateNewObject(
                                createdObjects[0],
                                AGGREGATE_GEOMETRY_NAME,
                                MESH_SCHEMA,
                                currentAggregateGeometry?.visible ?? true,
                            ),
                        ),
                    ),
                handleAdditionalSideEffects: () => {
                    resetVolumes();
                    resetParameterizedVolumes();
                    clearVisualizationAndIssuesForObject(currentAggregateGeometry!);
                    dispatch(disaggregateAllObjects());
                },
            },
        );
    }

    const titlesByStatus = new Map<TransformationStatus, string>([
        [TransformationStatus.Transforming, RESET_AGGREGATE_GEOM_MESSAGE],
        [TransformationStatus.Uploading, RESET_AGGREGATE_GEOM_MESSAGE],
        [TransformationStatus.Complete, RESET_AGGREGATE_SUCCESS_MESSAGE],
        [TransformationStatus.Failed, RESET_AGGREGATE_FAILURE_MESSAGE],
    ]);
    const percentagesByStatus = new Map<TransformationStatus, number>([
        [TransformationStatus.Transforming, 0],
        [TransformationStatus.Uploading, 70],
        [TransformationStatus.Complete, 100],
        [TransformationStatus.Failed, 100],
    ]);

    return (
        <>
            <Tooltip title="Reset Aggregate" placement="bottom" enterDelay={0}>
                <IconButton
                    onClick={performReset}
                    edge="end"
                    size="small"
                    automation-id={applyTrace('disaggregate-button')}
                >
                    <DisaggregateIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <TransformationProgressModal
                transformationStatus={transformationStatus}
                transformationTitles={titlesByStatus}
                transformationPercentages={percentagesByStatus}
                resetStatus={resetTransformationStatus}
            />
        </>
    );
}
