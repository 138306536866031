import type { SchemaVersion } from 'src/gtmProject/Project.types';

/**
 * Abstract base class for updating project JSON files from as source schema version to a target
 * schema version.
 */
export abstract class UpdaterBase {
    readonly sourceVersion: SchemaVersion;

    readonly targetVersion: SchemaVersion;

    constructor(sourceVersion: SchemaVersion, targetVersion: SchemaVersion) {
        this.sourceVersion = sourceVersion;
        this.targetVersion = targetVersion;
    }

    public update(projectJson: any): any {
        const updatedProjectJson = this.updateVersion(projectJson);
        return this.updateContent(updatedProjectJson);
    }

    /**
     * Updates the content of the project JSON data to match the target schema version.
     * This method must be implemented by subclasses to define the specific content updates
     * required.
     */
    protected abstract updateContent(projectJson: any): any;

    private updateVersion(projectJson: any): any {
        const updatedProjectJson = structuredClone(projectJson);
        updatedProjectJson.schemaVersion = this.targetVersion;
        return updatedProjectJson;
    }
}
